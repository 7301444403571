<template>
  <div
    style="cursor: n-resize; height: 10px; background-color: transparent"
    class="align-center justify-center d-flex"
    @mousedown="startResizeContent"
  >
    <v-icon>mdi-drag-horizontal-variant</v-icon>
  </div>
</template>

<script>
export default {
  name: 'ResizeVertical',
  data() {
    return {
      initialMouseY: 0,
      initialHeight: 0,
    };
  },
  methods: {
    startResizeContent(e) {
      this.initialMouseY = e.clientY; // Capture initial mouse position
      this.initialHeight = e.target.offsetParent.offsetHeight; // Capture the initial height of the parent
      document.addEventListener('mousemove', this.dragContent);
      document.addEventListener('mouseup', this.stopResizeContent);
    },
    dragContent(e) {
      const deltaY = e.clientY - this.initialMouseY; // Calculate the change in mouse position
      const newHeight = this.initialHeight + deltaY; // Calculate the new height
      if (newHeight >= 100) {
        this.$emit('size', newHeight); // Emit the new height
      }
    },
    stopResizeContent() {
      document.removeEventListener('mousemove', this.dragContent);
      document.removeEventListener('mouseup', this.stopResizeContent);
    },
  },
};
</script>

export const nonDhmeTasksTemplate = [
  {
    friendly_id: '1',
    title: 'Initialisatie',
    options: {
      type: 'dhme-init-phase',
    },
    weight: 5,
  },
  {
    friendly_id: '6',
    title: 'Ontwerpfase',
    options: {
      type: 'dhme-definitive-design-phase',
    },
    weight: 20,
  },
  {
    friendly_id: '10',
    title: 'Omgevingsvergunning bouwen',
    options: {
      type: 'dhme-environmental-permit-phase',
    },
    weight: 10,
  },
  {
    friendly_id: '24',
    title: 'Voorbereidingsfase',
    options: {
      type: 'dhme-execution-design-phase',
    },
    weight: 15,
  },
  {
    friendly_id: '101',
    title: 'Productie',
    options: {
      type: 'dhme-production-phase',
    },
    weight: 15,
  },
  {
    friendly_id: '102',
    title: 'Assemblage',
    options: {
      type: 'dhme-assembly-phase',
    },
    weight: 15,
  },
  {
    friendly_id: '30',
    title: 'Realisatie op locatie',
    options: {
      type: 'dhme-construction-site-phase',
    },
    weight: 50,
  },
];

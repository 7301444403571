<template>
  <!-- Wrap everything in a v-form -->
  <v-form ref="form" v-model="formIsValid" lazy-validation>
    <div class="d-flex align-center">
      <!-- Prognosed date -->
      <ant-input
        label="Voorspelde opleverdatum (optioneel)"
        top-margin="mt-0"
        class="mr-2"
        is-optional
      >
        <template #input-field>
          <v-menu
            v-model="prognosedMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                :value="formattedPrognosedDate"
                filled
                dense
                readonly
                prepend-inner-icon="mdi-calendar"
                placeholder="Datum"
                :rules="dateRulesOptional"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="prognosedDate"
              show-week
              @input="
                (value) => updatePlanningData('prognosed_handover_date', value)
              "
            />
          </v-menu>
        </template>
      </ant-input>

      <!-- Contracted date -->
      <ant-input
        label="Gecontracteerde opleverdatum"
        top-margin="mt-0"
        class="mr-2"
      >
        <template #input-field>
          <v-menu
            v-model="contractedMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedContractedDate"
                filled
                v-bind="attrs"
                dense
                readonly
                prepend-inner-icon="mdi-calendar"
                placeholder="Datum"
                :rules="dateRulesRequired"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="contractedDate"
              show-week
              @input="
                (value) => updatePlanningData('contracted_handover_date', value)
              "
            />
          </v-menu>
        </template>
      </ant-input>

      <!-- Phase start -->
      <ant-input label="Vanaf fase" top-margin="mt-0" class="mr-2">
        <template #input-field>
          <v-select
            v-model="phaseStart"
            filled
            item-text="text"
            item-value="value"
            dense
            placeholder="Fase"
            :items="
              metadata.firm === 'Daiwa House'
                ? [
                    { text: 'IF', value: 'dhme-init-phase' },
                    { text: 'SO', value: 'dhme-structure-design-phase' },
                    { text: 'VO', value: 'dhme-temporary-design-phase' },
                    { text: 'DO', value: 'dhme-definitive-design-phase' },
                  ]
                : [
                    { text: 'IF', value: 'dhme-init-phase' },
                    { text: 'DO', value: 'dhme-definitive-design-phase' },
                  ]
            "
            :rules="phaseStartRules"
          ></v-select>
        </template>
      </ant-input>

      <!-- Placement phase count -->
      <ant-input label="Aantal plaatsings fases" top-margin="mt-0">
        <template #input-field>
          <v-text-field
            :value="placementPhaseCount"
            filled
            dense
            type="number"
            placeholder="Aantal"
            :rules="placementPhaseCountRules"
            @input="handlePlacementPhaseInput"
          />
        </template>
      </ant-input>
    </div>

    <!-- Dates and derived fields -->
    <div class="d-flex mt-5">
      <!-- Start date -->
      <ant-input label="Start datum vanaf fase" top-margin="mt-0" class="mr-2">
        <template #input-field>
          <v-menu
            v-model="startDateMenu"
            :disabled="!contractedDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedStartDate"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                filled
                dense
                :disabled="!contractedDate || plannerLoading"
                placeholder="Datum"
                v-bind="attrs"
                :rules="dateRulesRequired"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              show-week
              @input="startDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </template>
      </ant-input>

      <!-- End date -->
      <ant-input
        label="Eind datum oplevering gereed"
        top-margin="mt-0"
        class="mr-2"
      >
        <template #input-field>
          <v-menu
            v-model="endDateMenu"
            :disabled="!contractedDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedEndDate"
                prepend-inner-icon="mdi-calendar-end"
                readonly
                filled
                :disabled="!contractedDate || plannerLoading"
                dense
                placeholder="Datum"
                v-bind="attrs"
                :rules="dateRulesRequired"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              :disabled="!startDate"
              show-week
              :min="startDate"
              :max="contractedDate"
              @input="endDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </template>
      </ant-input>

      <!-- Duration display -->
      <ant-input label="Duur" top-margin="mt-0" class="mr-2" is-optional>
        <template #input-field>
          <v-text-field
            :value="duration"
            :disabled="!contractedDate || plannerLoading"
            prepend-inner-icon="mdi-calendar-clock"
            readonly
            filled
            dense
            placeholder="Day(s)"
          ></v-text-field>
        </template>
      </ant-input>

      <!-- Slack display -->
      <ant-input label="Speling" top-margin="mt-0" class="mr-2" is-optional>
        <template #input-field>
          <v-text-field
            :value="slack"
            :disabled="!contractedDate || plannerLoading"
            prepend-inner-icon="mdi-calendar-arrow-right"
            readonly
            filled
            dense
            suffix="Dag(en)"
          ></v-text-field>
        </template>
      </ant-input>

      <v-spacer />

      <!-- Submit / Uitplannen -->
      <v-btn
        color="primary"
        class="mt-5 flex-1"
        :loading="plannerLoading"
        :disabled="plannerLoading"
        @click="submitForm"
      >
        Uitplannen
      </v-btn>
    </div>

    <!-- Gantt chart display -->
    <div
      class="ma-5 d-flex flex-column flex-1 flex-scroll-width overflow-x-auto"
      style="max-width: calc(100vw - 350px)"
    >
      <tasks-gantt-chart
        v-if="tasks.length > 0"
        :tasks="tasks.filter((t) => t.parent === null)"
      >
        <template #task-header-items>
          <tasks-gantt-default-item
            v-for="(task, index) in tasks.filter((t) => t.parent === null)"
            :key="`ant-gantt-header-${task.id}`"
            :task="task"
            :index="index"
            :parents="[]"
            :can-load-children="false"
          />
        </template>
      </tasks-gantt-chart>
    </div>
  </v-form>
</template>

<script>
import AntInput from '@/components/AntInput.vue';
import {mapGetters} from 'vuex';
import moment from 'moment';
import {dhmeTasksTemplate} from '@/modules/daiwa-house-modular-europe/ProjectIntake/DhmeTasksTemplate';
import {deleteTasksV2, importTasksV2, queryTasksV2,} from '@/services/api/v2/tasks.v2.api';
import TasksGanttChart from '@/components/Charts/TasksGanttChart.vue';
import TasksGanttDefaultItem from '@/components/Charts/TasksGanttDefaultItem.vue';
import {updateRecordV2} from '@/services/api/v2/records.v2.api';
import {queryTablesV2} from '@/services/api/v2/tables.v2.api';
import {nonDhmeTasksTemplate} from '@/modules/daiwa-house-modular-europe/ProjectIntake/NonDhmeTasksTemplate';

export default {
  name: 'DhmeProjectIntakePlanning',
  components: { TasksGanttDefaultItem, TasksGanttChart, AntInput },
  data: () => {
    return {
      // v-form validity
      formIsValid: true,

      // Menus, etc.
      startDate: null,
      startDateMenu: false,
      endDate: null,
      endDateMenu: false,
      prognosedMenu: false,
      contractedMenu: false,

      // Core data
      tasks: [],
      plannerLoading: false,
      planningData: null,
      planningDataTableId: null,

      // Phase-related inputs
      phaseStart: null,
      placementPhaseCount: 1,

      // Validation rules
      placementPhaseCountRules: [
        (v) => !!v || 'Aantal is vereist',
        (v) => /^\d+$/.test(v) || 'Moet een getal zijn',
        (v) => parseInt(v, 10) >= 1 || 'Minimaal 1',
        (v) => parseInt(v, 10) <= 25 || 'Maximaal 25',
      ],
      phaseStartRules: [
        // If your phaseStart is optional, you can skip. Otherwise:
        (v) => !!v || 'Selecteer een fase',
      ],
      dateRulesRequired: [
        (v) => !!v || 'Dit datumveld is verplicht',
        // We expect "DD-MM-YYYY" format in the text field's display:
        (v) => moment(v, 'DD-MM-YYYY', true).isValid() || 'Ongeldige datum',
      ],
      dateRulesOptional: [
        // Prognosed date is optional, so only check if filled
        (v) =>
          !v || moment(v, 'DD-MM-YYYY', true).isValid() || 'Ongeldige datum',
      ],
      dateRulesConditional: [
        // If you want the start/end date to be required only when contractedDate is set
        // or if you want it optional, tweak accordingly:
        (v) => {
          // If no date is entered, allow it. Make it optional:
          if (!v) return true;
          // Check format
          return moment(v, 'DD-MM-YYYY', true).isValid() || 'Ongeldige datum';
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      masterProject: 'project',
      intakeProject: 'dhmeProjectIntakeStore/selectedProject',
      metadata: 'dhmeProjectIntakeStore/selectedProjectMetadata',
    }),

    // Prognosed date as "YYYY-MM-DD" or null
    prognosedDate() {
      if (this.planningData?.prognosed_handover_date) {
        return moment(this.planningData.prognosed_handover_date).format(
          'YYYY-MM-DD'
        );
      }
      return null;
    },
    // Contracted date as "YYYY-MM-DD" or null
    contractedDate() {
      if (this.planningData?.contracted_handover_date) {
        return moment(this.planningData.contracted_handover_date).format(
          'YYYY-MM-DD'
        );
      }
      return null;
    },

    // Formatted displayed dates (DD-MM-YYYY) for the text-fields
    formattedPrognosedDate() {
      return this.planningData?.prognosed_handover_date
        ? moment(this.planningData.prognosed_handover_date).format('DD-MM-YYYY')
        : '';
    },
    formattedContractedDate() {
      return this.planningData?.contracted_handover_date
        ? moment(this.planningData.contracted_handover_date).format(
            'DD-MM-YYYY'
          )
        : '';
    },
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD-MM-YYYY') : '';
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD-MM-YYYY') : '';
    },

    // Derived fields
    duration() {
      if (this.endDate && this.startDate) {
        return moment(this.endDate).diff(moment(this.startDate), 'days');
      }
      return 0;
    },
    slack() {
      if (this.endDate && this.planningData?.contracted_handover_date) {
        return moment(this.planningData.contracted_handover_date).diff(
          moment(this.endDate),
          'days'
        );
      }
      return 0;
    },
  },
  async mounted() {
    // Fetch existing tasks
    await this.fetchPlanningTasks();

    // Fetch planning data
    await this.fetchPlanningData();
  },
  methods: {
    async fetchPlanningTasks() {
      const { tasks } = await queryTasksV2(this.project.license, [
        {
          column: 'project',
          operator: '=',
          values: [this.intakeProject.id],
        },
        {
          column: 'type',
          operator: '=',
          values: [
            'dhme-init-phase',
            'dhme-structure-design-phase',
            'dhme-temporary-design-phase',
            'dhme-definitive-design-phase',
            'dhme-environmental-permit-phase',
            'dhme-contract-phase',
            'dhme-technical-design-phase',
            'dhme-execution-design-phase',
            'dhme-production-phase',
            'dhme-assembly-phase',
            'dhme-construction-site-phase',
            'dhme-production-drawing-phase',
          ],
        },
      ]);

      this.tasks = tasks;
    },
    // Called when user types in the placement phase count
    handlePlacementPhaseInput(value) {
      // Basic parse and clamp; also triggers re-render
      let intVal = parseInt(value, 10);
      if (isNaN(intVal)) intVal = 1;
      if (intVal < 1) intVal = 1;
      if (intVal > 25) intVal = 25;
      this.placementPhaseCount = intVal;
    },

    // Submit the form: validate first, then plan tasks if valid
    submitForm() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        // If invalid, Vuetify shows errors automatically
        return;
      }
      // If valid, run planTasks logic
      this.planTasks();
    },

    async planTasks() {
      this.plannerLoading = true;

      let tasks = [];
      let date = moment(this.startDate);

      let template =
        this.metadata.firm === 'Daiwa House'
          ? [...dhmeTasksTemplate]
          : [...nonDhmeTasksTemplate];

      if (this.phaseStart) {
        const phaseStartIndex = template.findIndex(
          (t) => t.options.type === this.phaseStart
        );
        if (phaseStartIndex !== -1) {
          template = template.slice(phaseStartIndex);
        }
      }

      const topLevelTasks = template.filter(
        (t) => !t.parent && !t.friendly_parent_id
      );
      const totalWeight = topLevelTasks.reduce((acc, t) => acc + t.weight, 0);

      const validFriendlyIds = template.map((t) => t.friendly_id);
      const originalTasks = [...this.tasks];

      let allocatedTopLevelDays = 0;
      for (let i = 0; i < topLevelTasks.length; i++) {
        const task = topLevelTasks[i];
        const isLastTopLevel = i === topLevelTasks.length - 1;
        const rawDuration = this.duration * (task.weight / totalWeight);
        const taskDuration = isLastTopLevel
          ? this.duration - allocatedTopLevelDays // leftover for the last top-level task
          : Math.round(rawDuration);
        allocatedTopLevelDays += taskDuration;

        const endMoment = date.clone().add(taskDuration, 'days');
        const updateTask = this.tasks.find(
          (x) => x.task_type.type === task?.options?.type
        );
        const parentObj = this.setTaskData(
          validFriendlyIds,
          task,
          date,
          endMoment,
          updateTask
        );
        tasks.push(parentObj);

        if (
          task.hasChildren ||
          task.options.type === 'dhme-construction-site-phase'
        ) {
          if (task.options.type === 'dhme-construction-site-phase') {
            const placementPhaseCount = this.placementPhaseCount || 1;
            const parentDuration = endMoment.diff(date, 'days');
            const eachChildDur = parentDuration / placementPhaseCount;

            const existingPlacementTasks = this.tasks
              .filter((t) => t.task_type?.type === 'dhme-placement-phase')
              .sort((a, b) => a.number - b.number);

            let childDate = date.clone();
            let allocatedPlacementDays = 0;

            for (let c = 1; c <= placementPhaseCount; c++) {
              const isLastPlacement = c === placementPhaseCount;
              const realPlacement = isLastPlacement
                ? parentDuration - allocatedPlacementDays
                : Math.round(eachChildDur);
              allocatedPlacementDays += realPlacement;

              const childEndMoment = childDate
                .clone()
                .add(realPlacement, 'days');
              const placementFriendlyId = `${task.friendly_id}0${c}`;
              const updateChildTask = existingPlacementTasks[c - 1];

              const childObj = this.setTaskData(
                validFriendlyIds,
                {
                  friendly_id: placementFriendlyId,
                  title: `Placement Phase ${c}`,
                  options: { type: 'dhme-placement-phase' },
                  friendly_parent_id: parentObj.friendly_id,
                },
                childDate,
                childEndMoment,
                updateChildTask
              );
              tasks.push(childObj);
              childDate = childEndMoment.clone();
            }
            date = endMoment.clone();
          } else {
            const children = template.filter(
              (x) => x.friendly_parent_id === task.friendly_id
            );
            if (children.length > 0) {
              const parentDuration = endMoment.diff(date, 'days');
              const totalChildWeight = children.reduce(
                (sum, c) => sum + c.weight,
                0
              );
              let childDate = date.clone();
              let allocatedChildDays = 0;

              for (let c = 0; c < children.length; c++) {
                const child = children[c];
                const isLastChild = c === children.length - 1;
                const childRaw =
                  parentDuration * (child.weight / totalChildWeight);
                const roundedChildDuration = isLastChild
                  ? parentDuration - allocatedChildDays
                  : Math.round(childRaw);
                allocatedChildDays += roundedChildDuration;

                const childEndMoment = childDate
                  .clone()
                  .add(roundedChildDuration, 'days');
                const childUpdateTask = this.tasks.find(
                  (t) => t.friendly_id === child.friendly_id
                );

                const childObj = this.setTaskData(
                  validFriendlyIds,
                  child,
                  childDate,
                  childEndMoment,
                  childUpdateTask
                );
                tasks.push(childObj);
                childDate = childEndMoment.clone();
              }
              date = endMoment.clone();
            } else {
              date = endMoment.clone();
            }
          }
        } else {
          date = endMoment.clone();
        }
      }

      try {
        await importTasksV2(tasks);
      } catch (error) {
        console.error('Error importing tasks:', error);
      }

      this.plannerLoading = false;

      const newFriendlyIds = this.tasks.map((t) => t.id);
      const unusedTasks = originalTasks.filter(
        (ot) => !newFriendlyIds.includes(ot.id)
      );

      await deleteTasksV2(
        unusedTasks
          .filter((t) => t.id)
          .map((t) => ({ id: t.id, method: 'with-subtasks' }))
      );

      await this.fetchPlanningTasks();
    },

    // Utility to set or update a task object
    setTaskData(validFriendlyIds, task, startMoment, endMoment, updateTask) {
      // Filter invalid predecessors
      let predecessors = [];
      if (task.predecessors && Array.isArray(task.predecessors)) {
        predecessors = task.predecessors.filter((p) =>
          validFriendlyIds.includes(p)
        );
      }

      let obj;
      if (updateTask) {
        // Update existing
        obj = {
          id: updateTask.id,
          title: task.title,
          friendly_id: task.friendly_id,
          planned_start: startMoment.format('YYYY-MM-DD'),
          planned_end: endMoment.format('YYYY-MM-DD'),
          friendly_parent_id: task.friendly_parent_id,
        };
      } else {
        // Create new
        obj = {
          friendly_id: task.friendly_id,
          title: task.title,
          planned_start: startMoment.format('YYYY-MM-DD'),
          planned_end: endMoment.format('YYYY-MM-DD'),
          project: this.planningData.project_id,
          options: task.options,
          predecessors: predecessors,
          friendly_parent_id: task.friendly_parent_id,
        };
      }

      return obj;
    },

    // Fetch planning data from a custom table
    async fetchPlanningData() {
      const { planningdata } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_DATA',
            project: this.masterProject.id,
            as: 'planningdata',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.intakeProject.id,
                  },
                ],
              },
              ...['prognosed_handover_date', 'contracted_handover_date'].map(
                (key) => ({ name: key })
              ),
            ],
          },
        ],
      });

      // Store the first record found
      this.planningData = planningdata.records[0];
      this.planningDataTableId = planningdata.id;
      this.isLoading = false;
    },

    // Update date fields in the custom table
    async updatePlanningData(key, value) {
      this.isLoading = true;
      try {
        await updateRecordV2(this.planningDataTableId, this.planningData.id, {
          [key]: value,
        });
        this.planningData[key] = value;
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.isLoading = false;
        this.prognosedMenu = false;
        this.contractedMenu = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* Your component-specific styles go here */
</style>

export const dhmeBaseTasksTemplate = {
  'dhme-init-phase': [
    {
      friendly_id: '3',
      title: 'Afstemmen met OG',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '4',
      title: 'Basisopzet plan',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '3',
    },
    {
      friendly_id: '5',
      title: 'massastudie & telmodel',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '3',
    },
    {
      friendly_id: '6',
      title: 'Situatietekening',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '3',
    },
    {
      friendly_id: '7',
      title: 'Programma van Eisen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '3',
    },
    {
      friendly_id: '8',
      title: 'bestemmingplan toets',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '3',
    },
    {
      friendly_id: '9',
      title: 'Quickscan onderzoeken',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '3',
    },
    {
      friendly_id: '10',
      title: 'Omgevingsmanagement',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '3',
    },
    {
      friendly_id: '11',
      title: 'Gebruikersperiode/functie',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '3',
    },
    {
      friendly_id: '12',
      title: 'Demarcatielijst',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '3',
    },
    {
      friendly_id: '13',
      title: '    Contractvorming',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '14',
      title: 'Startgesprek Commercie & PO&E ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '13',
    },
    {
      friendly_id: '15',
      title:
        'Bouwbudget bepaling (op basis van ref projecten DHMEproject standaarden)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '13',
    },
    {
      friendly_id: '16',
      title: 'Concept planning opzetten',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '13',
    },
    {
      friendly_id: '17',
      title: 'Kostenoverzicht Engineering opzetten',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '13',
    },
    {
      friendly_id: '18',
      title: 'Afstemmen Bouwteamovereenkomst',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '13',
    },
    {
      friendly_id: '19',
      title: 'Bouwteamovereenkomst getekend',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '13',
    },
    {
      friendly_id: '20',
      title: 'Facturatie moment SO-fase',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '13',
    },
    {
      friendly_id: '21',
      title: 'Overdracht commercie -> Planontwikkeling',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '13',
    },
    {
      friendly_id: '22',
      title: 'Kickoff PO&E',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '13',
    },
  ],
  'dhme-structure-design-phase': [
    {
      friendly_id: '25',
      title: '    Ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '26',
      title: 'Bouwkundig ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '25',
    },
    {
      friendly_id: '27',
      title: '    Programma van eisen Opdrachtgever toetsen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '26',
    },
    {
      friendly_id: '28',
      title: '    Plattegronden, doorsnedes, gevels',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '26',
    },
    {
      friendly_id: '29',
      title: '    Gevelbeelden',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '26',
    },
    {
      friendly_id: '30',
      title: '    Situatietekening',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '26',
    },
    {
      friendly_id: '31',
      title: '    controle/akkoord OG',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '26',
    },
    {
      friendly_id: '32',
      title: 'Constructie ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '25',
    },
    {
      friendly_id: '33',
      title: '    Sonderingen ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '32',
    },
    {
      friendly_id: '34',
      title: '    Funderingsadvies',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '32',
    },
    {
      friendly_id: '35',
      title: '    Toetsing UPR/ Statica A4 verslag',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '32',
    },
    {
      friendly_id: '36',
      title: 'Installatie ontwerp ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '25',
    },
    {
      friendly_id: '37',
      title: '    Installatieconcept ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '36',
    },
    {
      friendly_id: '38',
      title: '    uitgangspunten Nuts/infra/grondwerk',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '36',
    },
    {
      friendly_id: '39',
      title: 'Vaststellen SO ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '25',
    },
    {
      friendly_id: '40',
      title: 'controle/akkoord OG',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '25',
    },
    {
      friendly_id: '41',
      title: '    Onderzoek',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '42',
      title: 'Quickscan bouwlocatie',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '43',
      title: 'stedebouwkundige check',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '44',
      title: 'Ecologie (quickscan flora-fauna)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '45',
      title: 'Archeologie',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '46',
      title: 'niet gesprongen explosieven onderzoek',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '47',
      title: 'stikstof ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '48',
      title: 'Geluid',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '49',
      title: 'Parkeren',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '50',
      title: 'Bodem',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '51',
      title: 'Klimaatscan',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '52',
      title: 'Milieuzonering',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '53',
      title: '<Nieuwe taak>',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '41',
    },
    {
      friendly_id: '54',
      title: '    Toetsing',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '55',
      title: 'BBL (hoofdstuk niveau)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '54',
    },
    {
      friendly_id: '56',
      title: 'MPG scan intern',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '54',
    },
    {
      friendly_id: '57',
      title: 'BENG',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '54',
    },
    {
      friendly_id: '58',
      title: '    Controle',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '59',
      title: 'Demarcatielijst',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
    {
      friendly_id: '60',
      title: 'check contractprocedure OG',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
    {
      friendly_id: '61',
      title: 'Afstemming 2D/3D/BIM',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
    {
      friendly_id: '62',
      title: 'klic-melding',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
    {
      friendly_id: '63',
      title: 'Nutsaansluitingen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
    {
      friendly_id: '64',
      title: 'SO Raming ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
    {
      friendly_id: '65',
      title: 'Controle & akkoord opdrachtgever',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
    {
      friendly_id: '66',
      title: 'SO akkoord',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
    {
      friendly_id: '67',
      title: 'Facturatie moment VO-fase',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
    {
      friendly_id: '68',
      title: 'Fase-document SO',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '58',
    },
  ],
  'dhme-temporary-design-phase': [
    {
      friendly_id: '71',
      title: '    Ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '72',
      title: 'Bouwkundig ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '71',
    },
    {
      friendly_id: '73',
      title: '    programma van eisen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '72',
    },
    {
      friendly_id: '74',
      title: '    Plattegronden, doorsnedes, gevels',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '72',
    },
    {
      friendly_id: '75',
      title: 'Constructie ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '71',
    },
    {
      friendly_id: '76',
      title: '    Toetsing UPR/ Statica A4 verslag',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '75',
    },
    {
      friendly_id: '77',
      title: '    Aanpassen plattegronden en doorsnede + verwerking UPR ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '75',
    },
    {
      friendly_id: '78',
      title: '    funderingstekeningen gereed',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '75',
    },
    {
      friendly_id: '79',
      title: 'Installatie ontwerp ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '71',
    },
    {
      friendly_id: '80',
      title: '    Installatieconcept met E+W',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '79',
    },
    {
      friendly_id: '81',
      title: 'Vaststellen VO ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '71',
    },
    {
      friendly_id: '82',
      title: 'controle/akkoord Opdrachtgever',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '71',
    },
    {
      friendly_id: '83',
      title: '    Onderzoek',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '84',
      title: 'Onderzoeken',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '83',
    },
    {
      friendly_id: '85',
      title: '    Geluidswering',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '86',
      title: '    externe veiligheid ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '87',
      title: '    verkennend bodemonderzoek',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '88',
      title: '    Ruimtelijke bouwactiviteit (omgevingswet)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '89',
      title: '    WKB/ kwaliteitsborger',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '90',
      title: '    Waterschap',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '91',
      title: '    Vaststellen Peilmaat (N.A.P.)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '92',
      title: '    BENG ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '93',
      title: '    labelbrekening',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '94',
      title: '    RC bepaling',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '95',
      title: '    Geluid',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '96',
      title: '    Daglicht',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '97',
      title: '    Ventilatie',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '98',
      title: '    Spuiberekening',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '99',
      title: '    MPG rapport',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '100',
      title: '    V&G ontwerp plan',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '101',
      title: '    Bouwveiligheidsplan',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '102',
      title: '    Brandveiligheid (door derden)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '103',
      title: '    Sterkte bij brand',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '104',
      title: '    WBDBO',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '105',
      title: '    Brandoverslag',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '84',
    },
    {
      friendly_id: '106',
      title: 'Onderzoeksresultaten verwerken in het plan',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '83',
    },
    {
      friendly_id: '107',
      title: '    Toetsing',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '108',
      title: 'BBL toets (afdeling niveau)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '107',
    },
    {
      friendly_id: '109',
      title: '    Controle',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '110',
      title: 'Concept Technische Omschrijving',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '109',
    },
    {
      friendly_id: '111',
      title: 'VO Raming + ABK (elementniveau + offerte aanvraag)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '109',
    },
    {
      friendly_id: '112',
      title: 'Controle OG (VO Raming)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '109',
    },
    {
      friendly_id: '113',
      title: 'VO Akkoord OG',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '109',
    },
    {
      friendly_id: '114',
      title: 'Facturatie moment DO-fase',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '109',
    },
    {
      friendly_id: '115',
      title: 'fase-document VO',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '109',
    },
    {
      friendly_id: '116',
      title: 'Check regels en procedure omgevingswet',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '109',
    },
    {
      friendly_id: '117',
      title: 'Aanvraag projectteam; PL & werkvoorbereider',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '109',
    },
  ],
  'dhme-definitive-design-phase': [
    {
      friendly_id: '119',
      title: '    Ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '120',
      title: 'Bouwkundig ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '119',
    },
    {
      friendly_id: '121',
      title: '    basis-details vaststellen, opzetten & beoordelen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '120',
    },
    {
      friendly_id: '122',
      title: '    Plattegronden, doorsnedes & gevels',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '120',
    },
    {
      friendly_id: '123',
      title: 'Constructie ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '119',
    },
    {
      friendly_id: '124',
      title: '    Constructie brekeningen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '123',
    },
    {
      friendly_id: '125',
      title: 'Installatie ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '119',
    },
    {
      friendly_id: '126',
      title: '    Gebouwgebonden installaties',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '125',
    },
    {
      friendly_id: '127',
      title: '    Installatie aansluitpunten vaststellen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '125',
    },
    {
      friendly_id: '128',
      title: 'Vaststellen DO ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '119',
    },
    {
      friendly_id: '129',
      title: 'Controle/akkoord opdrachtgever DO ontwerp',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '119',
    },
    {
      friendly_id: '130',
      title: '    Onderzoek',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '131',
      title: 'Check onderzoeksresultaten',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '130',
    },
    {
      friendly_id: '132',
      title: 'wijzigingen uit onderzoeken verwerken',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '130',
    },
    {
      friendly_id: '133',
      title: '    Toetsing',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '134',
      title: 'BBL toets (lid niveau)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '133',
    },
    {
      friendly_id: '135',
      title: 'Beoordeling DO & opgemaakte rapporten',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '133',
    },
    {
      friendly_id: '136',
      title: 'V&G ontwerp plan',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '133',
    },
    {
      friendly_id: '137',
      title: 'WKB/ kwaliteitsborger (omgevingswet)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '133',
    },
    {
      friendly_id: '138',
      title: 'Bouwveiligheidsplan',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '133',
    },
    {
      friendly_id: '139',
      title: '    Controle',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '140',
      title: 'Definitief Nutsen aanvraag en bouwplaats-aansluiting',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '139',
    },
    {
      friendly_id: '141',
      title:
        'Garantie bepaling & onderhoudstermijnen/bepaling (meenemen in prijs)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '139',
    },
    {
      friendly_id: '142',
      title: 'Technische Omschijving definitief maken',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '139',
    },
    {
      friendly_id: '143',
      title:
        'DO Begroting (regelniveau + offerte aanvraag) 70% gedekt met offertes',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '139',
    },
    {
      friendly_id: '144',
      title: 'Controle PL & werkvoorbereider (vooroverdracht)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '139',
    },
    {
      friendly_id: '145',
      title: 'Vooroverleg Nuts aanvraag & bouwplaats-aansluiting',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '139',
    },
    {
      friendly_id: '146',
      title: 'Controle OG',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '139',
    },
    {
      friendly_id: '147',
      title: 'DO akkoord ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '139',
    },
    {
      friendly_id: '148',
      title: 'fase-document DO',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '139',
    },
  ],
  'dhme-environmental-permit-phase': [
    {
      friendly_id: '151',
      title: '    Indienen vergunning',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '152',
      title: 'samenstellen verunningsstukken',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '151',
    },
    {
      friendly_id: '153',
      title: 'Uploaden vergunning + bijlage',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '151',
    },
    {
      friendly_id: '154',
      title: '    Reguliere procedure',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '155',
      title: 'Beoordeling bevoegd gezag',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '154',
    },
    {
      friendly_id: '156',
      title: 'Exclusief opschorting; n.a.v. verzoek aanvullende gegevens',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '154',
    },
    {
      friendly_id: '157',
      title: ' Verlenging gemeente (6weken) maximale beslistermijn',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '154',
    },
    {
      friendly_id: '158',
      title: 'Bezwaarperiode (risico OG in relatie tot productietekenwerk)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '154',
    },
    {
      friendly_id: '159',
      title: 'Afgifte onherroepelijke bouwvergunning',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '154',
    },
    {
      friendly_id: '160',
      title: 'BAG registratie',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '154',
    },
  ],
  'dhme-contract-phase': [
    {
      friendly_id: '163',
      title: '    Opstellen/afstemmen Aannemingsovereenkomst',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '164',
      title: '    Aannemingsovereenkomst gereed',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '165',
      title: '    Aannemingsovereenkomst getekend',
      options: {
        type: 'dhme-base-phase',
      },
    },
  ],
  'dhme-technical-design-phase': [
    {
      friendly_id: '168',
      title: '    Overdracht',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '169',
      title: 'Werkbegroting in SAP',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '168',
    },
    {
      friendly_id: '170',
      title: 'Overdracht PM -> PL',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '168',
    },
    {
      friendly_id: '171',
      title: 'Overdracht PO&E -> Uitvoering',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '168',
    },
    {
      friendly_id: '172',
      title: '    Werkvoorbereiding',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '173',
      title: 'Inkoop onderaannemers',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '172',
    },
    {
      friendly_id: '174',
      title: 'Tekenwerk onderaannemmers/leveranciers afronden',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '172',
    },
    {
      friendly_id: '175',
      title: 'Aanvullen details',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '172',
    },
    {
      friendly_id: '176',
      title: 'Bouw volgorde bepalen en fasering ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '172',
    },
    {
      friendly_id: '177',
      title: 'Basisunit bespreken met tekenkamer & productie',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '172',
    },
  ],
  'dhme-executio-design-phase': [
    {
      friendly_id: '180',
      title: '    Overdracht',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '181',
      title: 'startbespreking - overdracht aan Tekenkamer',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '180',
    },
    {
      friendly_id: '182',
      title: '    Uitwerking',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '183',
      title: 'Coderen elementen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '184',
      title: 'Uitwerking specials',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '185',
      title: 'Productie technisch uitwerken staal',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '186',
      title: 'Productie technisch uitwerken beton',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '187',
      title: 'Productie technisch uitwerken hsb',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '188',
      title:
        'Productie technisch uitwerken dakranden, gangwanden, gangdaken enz.',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '189',
      title: 'Unit tekenen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '190',
      title: 'A4tjes voor assemblage',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '191',
      title: 'Eindcontrole productiepakket',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '192',
      title: 'verwerken opmerkingen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '193',
      title: 'UO gereed',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
    {
      friendly_id: '194',
      title: 'Overdracht naar productie',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '182',
    },
  ],
  'dhme-production-phase': [
    {
      friendly_id: '197',
      title: '    Inkoop & voorbereiding',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '198',
      title: 'Inkoop productie',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '197',
    },
    {
      friendly_id: '199',
      title: 'Levertijden productie',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '197',
    },
    // {
    //   friendly_id: '200',
    //   title: '    Fabriek',
    //   options: {
    //     type: 'dhme-base-phase',
    //   },
    // },
    // {
    //   friendly_id: '201',
    //   title: 'Productie halffabricaat HSB',
    //   options: {
    //     type: 'dhme-base-phase',
    //   },
    //   friendly_parent_id: '200',
    // },
    // {
    //   friendly_id: '202',
    //   title: 'Productie halffabricaat staal',
    //   options: {
    //     type: 'dhme-base-phase',
    //   },
    //   friendly_parent_id: '200',
    // },
    // {
    //   friendly_id: '203',
    //   title: 'Productie halffabricaat beton',
    //   options: {
    //     type: 'dhme-base-phase',
    //   },
    //   friendly_parent_id: '200',
    // },
    // {
    //   friendly_id: '204',
    //   title: 'Assemblage units 100 units (22 p/w +1w voorbereideing)',
    //   options: {
    //     type: 'dhme-base-phase',
    //   },
    //   friendly_parent_id: '200',
    // },
  ],
  'dhme-construction-site-phase': [
    {
      friendly_id: '207',
      title: '    Bouwplaats',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '208',
      title: 'Bouwrijp maken',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '207',
    },
    {
      friendly_id: '209',
      title: 'Bouwplaatsinrichting (incl. BP-aansluitingen)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '207',
    },
    {
      friendly_id: '210',
      title: 'Verleggen/beschermen kabels en leidingen ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '207',
    },
    {
      friendly_id: '211',
      title: '    Fundering',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '212',
      title: 'Fundering',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '211',
    },
    {
      friendly_id: '213',
      title: 'Funderingsbalken',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '211',
    },
    {
      friendly_id: '214',
      title: 'Ankers stellen/ Installaties',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '211',
    },
    {
      friendly_id: '215',
      title: '    Montage & afwerking ',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '216',
      title: 'Opbouw units 100units (uitgangspunt circa 20-24 per dag)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '215',
    },
    {
      friendly_id: '217',
      title: 'Dakrand',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '215',
    },
    {
      friendly_id: '218',
      title: 'Dakbedekking',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '215',
    },
    {
      friendly_id: '219',
      title: 'Gevelwerkzaamheden',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '215',
    },
    {
      friendly_id: '220',
      title: 'Installaties ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '215',
    },
    {
      friendly_id: '221',
      title: 'Aansluiten nutsen',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '215',
    },
    {
      friendly_id: '222',
      title: 'Afbouw',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '215',
    },
    {
      friendly_id: '223',
      title: 'Terrein inrichting ',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '215',
    },
    {
      friendly_id: '224',
      title: '    Oplevering',
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '225',
      title: 'opleverdossier aanleveren',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '224',
    },
    {
      friendly_id: '226',
      title: 'opleveringsinspectie (kleuren & installaties testen)',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '224',
    },
    {
      friendly_id: '227',
      title: 'Proces Verbaal van Oplevering gereed',
      options: {
        type: 'dhme-base-phase',
      },
      friendly_parent_id: '224',
    },
  ],
};

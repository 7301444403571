<script>
import { defineComponent } from 'vue';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import { mapGetters } from 'vuex';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import {
  createRecordV2,
  deleteRecordV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';
import FileHandlerService from '@/services/file-handler';
import { getColumnsV2 } from '@/services/api/v2/columns.v2.api';
import DeleteDialog from '@/components/DeleteDialog.vue';

export default defineComponent({
  name: 'DHMEManualImportDrawings',
  components: {
    DeleteDialog,
    DynamicDataTable,
  },
  mixins: [InputRulesMixin],
  data: () => {
    return {
      manualImportDrawingHeaders: [
        {
          text: 'Title',
          value: 'title',
          input: true,
          hasSlot: true,
          type: 'text',
          width: '400px',
        },
        {
          text: 'Drawing',
          value: 'drawing',
          type: 'text',
        },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      manualImportDrawingRecords: [],
      manualImportDrawingColumns: [],
      manualImportDrawingTableId: null,
      drawingToDelete: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['project']),
  },
  mounted() {
    this.fetchDrawings();
  },
  methods: {
    async uploadDrawings(event) {
      this.isLoading = true;
      const files = Array.from(event.target.files);

      for (const file of files) {
        const drawing = await FileHandlerService.handleFile(file);

        const uploadedFile = await createRecordV2(
          this.manualImportDrawingTableId,
          {
            title: drawing.name,
            drawing: drawing,
          }
        );

        this.manualImportDrawingRecords.push(uploadedFile);
      }

      this.isLoading = false;
      event.target.value = ''; // Reset the file input
    },
    async fetchDrawings() {
      this.isLoading = true;
      let { drawings } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_DRAWINGS',
            project: this.project.id,
            as: 'drawings',
            columns: [
              {
                name: 'title',
              },
              {
                name: 'drawing',
              },
            ],
          },
        ],
      });

      this.manualImportDrawingRecords = drawings.records;
      this.manualImportDrawingTableId = drawings.id;
      await this.fetchColumns();
      this.isLoading = false;
    },
    async deleteDrawing() {
      this.isLoading = true;
      await deleteRecordV2(
        this.manualImportDrawingTableId,
        this.drawingToDelete.id
      );
      this.manualImportDrawingRecords = this.manualImportDrawingRecords.filter(
        (x) => x.id !== this.drawingToDelete.id
      );
      this.drawingToDelete = null;
      this.isLoading = false;
    },
    async fetchColumns() {
      this.manualImportDrawingColumns = await getColumnsV2(
        this.manualImportDrawingTableId
      );
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    setupDelete(drawing) {
      this.drawingToDelete = drawing;
    },
    async updateRecord(recordId, column, value) {
      this.isLoading = true;
      let body = {};
      body[column] = value;

      const updatedRecord = await updateRecordV2(
        this.manualImportDrawingTableId,
        recordId,
        body
      );

      Object.assign(
        this.manualImportDrawingRecords.find((x) => x.id === recordId),
        updatedRecord
      );

      this.isLoading = false;
    },
  },
});
</script>

<template>
  <dynamic-data-table
    table-title="Drawings"
    :table-headers="manualImportDrawingHeaders"
    :table-records="manualImportDrawingRecords"
    :table-columns="manualImportDrawingColumns"
    auto-sort-column="object_type"
    class="flex-grow-1 ant-glass-background radius-0"
    :project-id="project.id"
    :table-id="manualImportDrawingTableId"
    export-file-name="manual_import_module_export"
    has-options
    can-delete
    @deleteItem="setupDelete"
  >
    <template
      v-for="header in manualImportDrawingHeaders.filter((x) => x.input)"
      #[`item.${header.value}`]="{ value, rowId, item }"
    >
      <td :key="header.value" class="ant-border-left">
        <v-text-field
          :value="value"
          dense
          single-line
          hide-details
          filled
          :type="header.type"
          clearable
          :suffix="header.suffix"
          @change="updateRecord(item.id, header.value, $event)"
        />
      </td>
    </template>
    <template #table-actions>
      <delete-dialog
        :title="`Are you sure you want to delete drawing ${drawingToDelete?.title}`"
        :dialog="drawingToDelete !== null"
        @closeDialog="drawingToDelete = null"
        @deleteAction="deleteDrawing"
      />
      <v-btn small elevation="0" color="primary" @click="triggerFileInput" :loading="isLoading" :disabled="isLoading">
        <v-icon>mdi-plus</v-icon>
        Add
      </v-btn>
      <input
        ref="fileInput"
        type="file"
        style="display: none"
        accept="application/pdf"
        multiple
        @change="uploadDrawings"
      />
    </template>
  </dynamic-data-table>
</template>

<style scoped lang="scss">
td {
  padding: 0 !important;
}

::v-deep .v-text-field__details {
  height: 0;
  overflow: hidden;
  min-height: 0;
}

::v-deep .x-small-text-field {
  max-width: 100px !important;
}

::v-deep .small-text-field {
  max-width: 150px !important;
}

::v-deep .normal-text-field {
  max-width: 200px !important;
}
</style>

<template>
  <div class="flex-1 d-flex">
    <div id="gantt_container" style="height: 100%; width: 100%" />
    <dhme-project-gantt-dialog
      key="gantt-dialog"
      :is-shown="showTaskDialog"
      :project="project"
      :parent-id="parentId"
      :selected-task="selectedTask"
      @closeDialog="hideModal"
      @onSubmit="onTaskSubmit"
      @onDelete="onTaskDelete"
    />
  </div>
</template>

<script>
import { gantt } from 'dhtmlx-gantt';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { DELETE_METHOD_SINGLE } from '@/components/Tasks/task';
import { mapTasks, mapTaskToGantt } from '@/services/dhtmlx-gantt/chart-helper';
import DhmeProjectGanttDialog from '@/modules/daiwa-house-modular-europe/ProjectGantt/DhmeProjectGanttDialog.vue';

gantt.plugins({
  marker: true,
  export_api: true,
  auto_scheduling: true,
  critical_path: true,
});

export default {
  name: 'DhmeProjectGanttChart',
  components: { DhmeProjectGanttDialog },
  props: {
    tasks: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      showCriticalPath: false,
      treeToggle: false,
      showTaskDialog: false,
      selectedTask: null,
      parentId: '',
      ganttTaskId: null,
      currentDateMarkerInterval: null,
      zoomConfig: {
        levels: [
          {
            name: 'day',
            scale_height: 27,
            min_column_width: 80,
            scales: [{ unit: 'day', step: 1, format: '%d %M' }],
          },
          {
            name: 'week',
            scale_height: 50,
            min_column_width: 50,
            scales: [
              {
                unit: 'week',
                step: 1,
                format: function (date) {
                  const dateToStr = gantt.date.date_to_str('%d %M');
                  const endDate = gantt.date.add(date, -6, 'day');
                  const weekNum = gantt.date.date_to_str('%W')(date);
                  return (
                    '#' +
                    weekNum +
                    ', ' +
                    dateToStr(date) +
                    ' - ' +
                    dateToStr(endDate)
                  );
                },
              },
              { unit: 'day', step: 1, format: '%j %D' },
            ],
          },
          {
            name: 'month',
            scale_height: 50,
            min_column_width: 120,
            scales: [
              { unit: 'month', format: '%F, %Y' },
              { unit: 'week', format: 'Week #%W' },
            ],
          },
          {
            name: 'quarter',
            height: 50,
            min_column_width: 90,
            scales: [
              { unit: 'month', step: 1, format: '%M' },
              {
                unit: 'quarter',
                step: 1,
                format: function (date) {
                  const dateToStr = gantt.date.date_to_str('%M');
                  const endDate = gantt.date.add(
                    gantt.date.add(date, 3, 'month'),
                    -1,
                    'day'
                  );
                  return dateToStr(date) + ' - ' + dateToStr(endDate);
                },
              },
            ],
          },
          {
            name: 'year',
            scale_height: 50,
            min_column_width: 30,
            scales: [{ unit: 'year', step: 1, format: '%Y' }],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['project']),
    ...mapGetters({
      moduleData: 'tasksGantt/data',
    }),
    tasksData() {
      if (!this.tasks?.length) {
        return {
          tasks: [],
          links: [],
        };
      }
      return mapTasks(this.tasks);
    },
  },
  watch: {
    tasksData(val) {
      this.parseData(val);
    },
    showCriticalPath(val) {
      gantt.config.highlight_critical_path = val;
      gantt.render();
    },
    treeToggle(val) {
      val ? this.expandAll() : this.closeAll();
    },
  },
  mounted() {
    this.init();
    this.parseData(this.tasksData);
    this.showDate(new Date());

    this.renderMarkerArea();
    this.setDayOffs();

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    gantt.clearAll();
    gantt.detachAllEvents();
    clearInterval(this.currentDateMarkerInterval);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      gantt.setSizes();
    },

    expandAll() {
      this.tasksData.tasks.forEach((t) => gantt.open(t.id));
    },

    closeAll() {
      this.tasksData.tasks.forEach((t) => gantt.close(t.id));
    },

    exportToExcel() {
      const originalColumns = gantt.config.columns;
      gantt.config.columns = [
        ...originalColumns,
        {
          name: 'parent',
          label: 'Parent',
          align: 'center',
          width: 100,
          template: (obj) => {
            const guidRegex =
              /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
            if (guidRegex.test(obj.parent)) {
              const parentTask = gantt.getTask(obj.parent);
              return parentTask ? `#${parentTask.number}` : '-';
            }
            return '-';
          },
        },
      ];

      gantt.exportToExcel({
        name: `${this.project?.name}.xlsx`,
      });

      gantt.config.columns = originalColumns;
      gantt.render();
    },
    exportToMSProject() {
      gantt.exportToMSProject({
        name: `${this.project?.name}.xml`,
        skip_circular_links: false,
      });
    },
    exportToPdf() {
      gantt.exportToPDF({
        name: `${this.project?.name}.pdf`,
        skip_circular_links: false,
      });
    },
    setZoomLevel(level) {
      gantt.ext.zoom.setLevel(level);
    },

    init() {
      gantt.ext.zoom.init(this.zoomConfig);
      gantt.ext.zoom.setLevel('month');
      gantt.templates.rightside_text = (start, end, task) => null;
      gantt.templates.leftside_text = (start, end, task) => null;

      gantt.templates.progress_text = function (start, end, task) {
        if (task.progress > 0) {
          return (
            "<span style='text-align:left; padding-left: 10px'>" +
            Math.round(task.progress * 100) +
            '% </span>'
          );
        }
        return null;
      };

      gantt.templates.parse_date = function (date) {
        return new Date(date);
      };
      gantt.templates.format_date = function (date) {
        return date.toISOString();
      };
      gantt.config.columns = [
        {
          name: 'number',
          label: '',
          tree: true,
          width: '70',
          template: function (obj) {
            return `#${obj.number}`;
          },
        },
        {
          name: 'title',
          label: this.$t('modules.projectGantt.headers.title'),
          width: '200',
        },
        {
          name: 'status',
          label: this.$t('modules.projectGantt.headers.status'),
          align: 'center',
          width: '50',
        },
        {
          name: 'duration',
          label: this.$t('modules.projectGantt.headers.duration'),
          align: 'center',
          width: '50',
        },
        {
          name: 'start_date',
          label: this.$t('modules.projectGantt.headers.startDate'),
          align: 'center',
          sort: true,
          width: '100',
          template: function (obj) {
            return moment(obj.start_date).format('DD-MM-YYYY');
          },
        },
        {
          name: 'end_date',
          label: this.$t('modules.projectGantt.headers.endDate'),
          align: 'center',
          sort: true,
          width: '100',
          template: function (obj) {
            return moment(obj.end_date).format('DD-MM-YYYY');
          },
        },
        {
          name: 'party',
          label: this.$t('modules.projectGantt.headers.party'),
          align: 'center',
          sort: true,
          width: '100',
        },
        { name: 'add', label: '', width: '50' },
      ];

      gantt.config.inherit_scale_class = true;
      gantt.config.work_time = true;
      gantt.config.sort = true;
      gantt.config.auto_scheduling = true;
      gantt.config.auto_scheduling_strict = true;
      gantt.config.scroll_size = 10;

      gantt.templates.scale_cell_class = function (date) {
        if (moment(date).isSame(moment(), 'day')) {
          return 'current_date';
        }
      };
      gantt.templates.timeline_cell_class = function (_, date) {
        if (moment(date).isSame(moment(), 'day')) {
          return 'current_date';
        }
        if (!gantt.isWorkTime(date) && gantt.getScale().unit !== 'year') {
          return 'day-off';
        }
        return '';
      };

      gantt.attachEvent('onAfterTaskDrag', this.onAfterTaskDrag, {});
      gantt.attachEvent('onBeforeLinkAdd', this.onBeforeLinkAdd, {});
      gantt.attachEvent('onBeforeLinkUpdate', this.onBeforeLinkUpdate, {});
      gantt.attachEvent('onAfterLinkDelete', this.onAfterLinkDelete, {});

      gantt.attachEvent('onBeforeTaskDrag', (id) => {
        const task = gantt.getTask(id);
        // Store the original dates on the task itself
        task._originalStartDate = new Date(task.start_date);
        task._originalEndDate = new Date(task.end_date);
        return true; // Allow the drag to proceed
      });

      gantt.showLightbox = this.showModal;

      const todayMarker = gantt.addMarker({
        start_date: new Date(),
        css: 'today',
        title: 'Now',
        text: 'Now',
      });

      this.currentDateMarkerInterval = setInterval(() => {
        const today = gantt.getMarker(todayMarker);
        if (today) {
          today.start_date = new Date();
          gantt.updateMarker(todayMarker);
        }
      }, 1000 * 3600); // updates marker position every hour
      gantt.renderMarkers();

      gantt.init('gantt_container');

      gantt.sort('start_date');
    },

    // Helper: Get current UI state (scroll and expanded tasks)
    getCurrentState() {
      const scrollState = gantt.getScrollState();
      const expandedTasks = [];
      gantt.eachTask((task) => {
        if (task.$open) {
          expandedTasks.push(task.id);
        }
      });
      return { scrollState, expandedTasks };
    },

    // Helper: Restore UI state
    restoreState({ scrollState, expandedTasks }) {
      // Restore expanded tasks
      expandedTasks.forEach((id) => {
        if (gantt.isTaskExists(id)) {
          gantt.open(id);
        }
      });

      // Restore scroll position
      gantt.scrollTo(scrollState.x, scrollState.y);
    },

    // Central method to parse data and restore state
    parseData(data) {
      const state = this.getCurrentState();
      gantt.clearAll();
      gantt.parse(data);
      this.restoreState(state);
    },

    showDate(data) {
      gantt.showDate(data);
    },
    renderMarkerArea() {
      const node = gantt.$marker_area;
      const parent = document.querySelector('.gantt_data_area');
      parent.insertAdjacentElement('beforeend', node);
    },
    setDayOffs() {
      const calendarRecords =
        this.moduleData?.CFFA_DHME_CALENDAR?.records || [];

      if (calendarRecords.length) {
        calendarRecords.forEach(({ date }) => {
          gantt.setWorkTime({ date: new Date(date), hours: false });
        });
      }
    },

    onAfterTaskDrag(taskId) {
      const task = gantt.getTask(taskId);

      const startDate = moment(task.start_date)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment(task.end_date)
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss');

      const body = {
        planned_end: endDate,
        planned_start: startDate,
      };

      this.$store
        .dispatch('tasksGantt/updateTask', { taskId, body })
        .catch(() => {
          // Update failed, revert to original dates
          task.start_date = task._originalStartDate;
          task.end_date = task._originalEndDate;
          gantt.updateTask(taskId);

          // Clean up the original date properties
          delete task._originalStartDate;
          delete task._originalEndDate;
        });
    },

    onAfterLinkDelete(id, link) {
      const { source } = link;
      if (id && source) {
        this.$store
          .dispatch('tasksGantt/deleteTaskRelation', {
            taskId: source,
            relationId: id,
          })
          .catch(() => {
            gantt.addLink(link);
          });
      }
    },

    onBeforeLinkAdd(id, link) {
      if (['0', 0].includes(link.type)) {
        const { source, target } = link;
        const sourceTask = gantt.getTask(source);
        const targetTask = gantt.getTask(target);

        this.$store.dispatch('tasksGantt/createTaskRelation', {
          previousId: sourceTask.id,
          taskId: targetTask.id,
        });
        return false; // Prevent default link creation by gantt
      }
      this.$store.commit('showNotification', {
        content: 'This link type is forbidden',
        color: 'error',
      });
      return false;
    },
    onBeforeLinkUpdate() {
      return false;
    },

    showModal(id) {
      const task = gantt.getTask(id);
      if (task.$new) {
        this.ganttTaskId = id;
        this.parentId = task.parent || '';
      } else {
        this.selectedTask = this.tasks.find((task) => task.id === id);
      }
      this.showTaskDialog = true;
    },

    hideModal() {
      try {
        const task = this.ganttTaskId ? gantt.getTask(this.ganttTaskId) : {};
        if (task.$new) {
          gantt.deleteTask(this.ganttTaskId);
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.showTaskDialog = false;
        this.selectedTask = null;
        this.ganttTaskId = null;
      }
    },

    async onTaskSubmit({ isEditing, payload }) {
      const { id: taskId, ...body } = payload;
      if (isEditing) {
        this.$store
          .dispatch('tasksGantt/updateTask', { taskId, body })
          .then(() => {
            this.hideModal();
          });
      } else {
        const task = this.ganttTaskId ? gantt.getTask(this.ganttTaskId) : {};
        const data = await this.$store.dispatch('tasksGantt/createTask', {
          body: payload,
        });
        if (task.$new) {
          gantt.addTask(mapTaskToGantt(data));
        }
        this.hideModal();
      }
    },

    async onTaskDelete(taskId) {
      const success = await this.$store.dispatch('tasksGantt/deleteTasks', {
        tasks: [{ id: taskId, method: DELETE_METHOD_SINGLE }],
      });
      if (success) {
        this.$store.commit('tasksGantt/task_delete_success', taskId);
        // parseData is called by watcher after tasks change in Vuex
      }
      this.hideModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
::v-deep {
  .gantt_task_progress {
    text-align: left;
    box-sizing: border-box;
    color: white;
    font-weight: bold;
  }

  .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }
  .gantt_task_row.gantt_selected .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }
  .gantt_scale_cell {
    user-select: none;
    &.current_date {
      background-color: lightgray;
    }
  }
  .gantt_task_cell {
    &.current_date {
      background-color: lightgray;
    }
  }
  .gantt_tree_indent {
    width: 10px;
  }
  .status {
    background-color: #1de9b6;
  }

  .hidden-column {
    display: none;
  }
}
</style>

<script>
import { dhmeTasksTemplate } from '@/modules/daiwa-house-modular-europe/ProjectIntake/DhmeTasksTemplate';
import { nonDhmeTasksTemplate } from '@/modules/daiwa-house-modular-europe/ProjectIntake/NonDhmeTasksTemplate';

export default {
  name: 'DhmeWkbPhaseChip',
  props: {
    type: {
      required: false,
      type: String,
      default: () => '',
    },
    text: {
      required: true,
      type: String,
    },
  },
  computed: {
    dhmeTitle() {
      return (
        dhmeTasksTemplate.find((x) => x.options.type === this.type)?.title ??
        '- (Not Found)'
      );
    },
    jsTitle() {
      return (
        nonDhmeTasksTemplate.find((x) => x.options.type === this.type)?.title ??
        '- (Not Found)'
      );
    },
  },
};
</script>

<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-chip v-bind="attrs" class="ma-1" v-on="on">
        <v-icon v-if="!type" color="warning">mdi-alert</v-icon>
        {{ text }}
      </v-chip>
    </template>
    <div style="display: flex; flex-direction: column">
      <span><strong>DHME:</strong> {{ dhmeTitle }}</span>
      <span><strong>JS:</strong> {{ jsTitle }}</span>
    </div>
  </v-tooltip>
</template>

<style scoped lang="scss"></style>

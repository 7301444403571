<script>
import { defineComponent } from 'vue';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { DHME_MANUAL_IMPORT } from '@/modules/modules';
import FileHandlerService from '@/services/file-handler';
import moment from 'moment/moment';
import { ANT_DELIMITER } from '@/services/uuid-helper';
import LocalStorageService from '@/services/local-storage';
import { moduleContentsHeaders } from '@/modules/daiwa-house-modular-europe/ManualImport/columns';

export default defineComponent({
  name: 'DhmeManualImportModuleContents',
  components: { DynamicDataTable },
  data: () => {
    return {
      tableHeaders: moduleContentsHeaders,
      isLoading: false,
      selectedCell: null,
      cellValue: null,
      csvImportFile: null,
      deleteMode: false,
      currentCellValue: null,
      moduleTypeSearchValue: null,
      previousScrollLeft: 0,
      showOverlay: false,
      overlayHeight: 0,
      ctrlAltDown: false,
      editTableHeaders: false,
      isGenerating: false,
      tableOptions: {},
      columnTab: 'hsb',
      lockedCells: new Set(),
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      moduleContents: 'dhmeManualImportStore/moduleContents',
      moduleContentsTable: 'dhmeManualImportStore/moduleContentsTableId',
      modules: 'dhmeManualImportStore/modules',
      lastElementGeneration: 'dhmeManualImportStore/lastElementGeneration',
    }),

    manualImportModuleContentsHeaders() {
      let headers = [];
      if (this.editTableHeaders) {
        headers = this.tableHeaders;
      } else {
        headers = this.tableHeaders.filter((item) => item.shown || !item.input);
      }

      switch (this.columnTab) {
        case 'overig':
          return headers.filter((x) => {
            if (x.input) {
              return (
                !x.value.toLowerCase().startsWith('hsb') &&
                !x.value.toLowerCase().includes('koz')
              );
            } else {
              return x;
            }
          });
        case 'hsb':
          return headers.filter((x) => {
            if (x.input) {
              return x.value.toLowerCase().startsWith(this.columnTab);
            } else {
              return x;
            }
          });
        case 'koz':
          return headers.filter((x) => {
            if (x.input) {
              return x.value.toLowerCase().includes(this.columnTab);
            } else {
              return x;
            }
          });
      }
    },

    manualImportModuleContentsRecords() {
      let records = [
        ...new Map(
          this.modules
            .filter((item) => item.module_type)
            .map((item) => [item.module_type, item])
        ).values(),
      ];

      if (this.moduleTypeSearchValue) {
        records = records.filter((record) =>
          record.module_type.includes(this.moduleTypeSearchValue)
        );
      }

      return records
        .map((item) => {
          return {
            module_type: item.module_type,
          };
        })
        .sort((a, b) => a?.module_type.localeCompare(b?.module_type));
    },
    overlayRecords() {
      // Pagination logic
      const startIndex =
        (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
      const endIndex = startIndex + this.tableOptions.itemsPerPage;

      return this.manualImportModuleContentsRecords.slice(startIndex, endIndex);
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_MANUAL_IMPORT
      ).id;
    },
    focusedRowModuleType() {
      return this.selectedCell?.split('$%ANT%$')[0] ?? null;
    },
  },
  async mounted() {
    await this.$store.dispatch('dhmeManualImportStore/fetchModules');
    await this.$store.dispatch('dhmeManualImportStore/fetchModuleContents');
    window.addEventListener('keydown', this.setEventListeners);
    window.addEventListener('keyup', this.setEventListeners);

    this.$nextTick(() => {
      const tableWrapper = this.$el.querySelector('.v-data-table__wrapper');
      tableWrapper.addEventListener('scroll', this.handleScroll);
      this.overlayHeight = tableWrapper.offsetHeight - 35;
    });

    let value = LocalStorageService.getValue(
      `${this.project.id}.${this.moduleId}`
    );
    if (value) {
      let list = value.split('|');
      list.forEach((header) => {
        let headerItem = this.manualImportModuleContentsHeaders.find(
          (item) => item.value === header
        );
        if (headerItem) {
          headerItem.shown = false;
        }
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.setEventListeners);
    window.removeEventListener('keyup', this.setEventListeners);

    const tableWrapper = this.$el.querySelector('.v-data-table__wrapper');
    tableWrapper.removeEventListener('scroll', this.handleScroll);

    let value = this.mapHiddenHeaders();
    if (value) {
      LocalStorageService.setValue(
        `${this.project.id}.${this.moduleId}`,
        value
      );
    }
  },
  methods: {
    mapHiddenHeaders() {
      return this.tableHeaders
        .filter((item) => !item.shown && item.input)
        .map((item) => item.value)
        .join('|');
    },
    handleScroll(event) {
      const tableWrapper = event.target;
      if (tableWrapper) {
        if (tableWrapper.scrollLeft !== this.previousScrollLeft) {
          this.previousScrollLeft = tableWrapper.scrollLeft;

          this.showOverlay = tableWrapper.scrollLeft > 50;

          this.$nextTick(() => {
            const overlay = this.$el.querySelector('.overlay');
            if (overlay) {
              overlay.scrollTop = tableWrapper.scrollTop;
            }
          });
        } else {
          // vertical scrolling
          const overlay = this.$el.querySelector('.overlay');
          if (overlay) {
            overlay.scrollTop = tableWrapper.scrollTop;
          }
        }
      }
    },
    ANT_DELIMITER() {
      return ANT_DELIMITER;
    },
    getDateFromTimestamp(timestamp) {
      return moment.unix(parseInt(timestamp)).format('YYYY-MM-DD HH:mm');
    },
    async setEventListeners(event) {
      if (
        event.ctrlKey &&
        event.altKey &&
        event.code === 'KeyD' &&
        event.type === 'keydown'
      ) {
        this.deleteMode = !this.deleteMode;
        this.selectedCell = null;
      }

      if (this.selectedCell && event.type === 'keydown') {
        if (event.altKey && event.ctrlKey) {
          this.ctrlAltDown = true;
          let split = this.selectedCell.split(ANT_DELIMITER);
          let columnIndex = this.manualImportModuleContentsHeaders.findIndex(
            (column) => column.value === split[1]
          );
          let recordIndex = this.manualImportModuleContentsRecords.findIndex(
            (record) => record.module_type === split[0]
          );
          switch (event.key) {
            case 'ArrowLeft':
              if (this.cellValue !== this.currentCellValue) {
                await this.createOrUpdateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex - 1;
              this.cellValue = this.getCellValue(
                this.manualImportModuleContentsRecords[recordIndex].module_type,
                this.manualImportModuleContentsHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowRight':
              if (this.cellValue !== this.currentCellValue) {
                await this.createOrUpdateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex + 1;
              this.cellValue = this.getCellValue(
                this.manualImportModuleContentsRecords[recordIndex].module_type,
                this.manualImportModuleContentsHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowUp':
              if (this.cellValue !== this.currentCellValue) {
                await this.createOrUpdateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex - 1;
              this.cellValue = this.getCellValue(
                this.manualImportModuleContentsRecords[recordIndex].module_type,
                this.manualImportModuleContentsHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowDown':
              if (this.cellValue !== this.currentCellValue) {
                await this.createOrUpdateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex + 1;
              this.cellValue = this.getCellValue(
                this.manualImportModuleContentsRecords[recordIndex].module_type,
                this.manualImportModuleContentsHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;

            case 'Delete':
              await this.createOrUpdateRecord(
                this.manualImportModuleContentsRecords[recordIndex].id,
                this.manualImportModuleContentsHeaders[columnIndex].value,
                null
              );
              this.currentCellValue = null;
              return;
          }

          if (
            columnIndex >= 2 &&
            columnIndex <= this.manualImportModuleContentsHeaders.length &&
            recordIndex >= 0 &&
            recordIndex < this.manualImportModuleContentsRecords.length
          ) {
            this.selectedCell = `${this.manualImportModuleContentsRecords[recordIndex].module_type}${ANT_DELIMITER}${this.manualImportModuleContentsHeaders[columnIndex].value}`;
          }
        } else {
          this.ctrlAltDown = false;
        }
      } else {
        this.ctrlAltDown = false;
      }
    },
    async updateAndRowDown() {
      let [moduleType, column] = this.selectedCell.split(this.ANT_DELIMITER());

      if (this.lockedCells.has(`${moduleType}_${column}`)) {
        return;
      }

      await this.createOrUpdateRecord(
        moduleType,
        column,
        this.currentCellValue
      );

      let columnIndex = this.manualImportModuleContentsHeaders.findIndex(
        (col) => col.value === column
      );
      let recordIndex = this.manualImportModuleContentsRecords.findIndex(
        (record) => record.module_type === moduleType
      );

      recordIndex = recordIndex + 1;

      if (
        columnIndex >= 2 &&
        columnIndex < this.manualImportModuleContentsHeaders.length &&
        recordIndex >= 0 &&
        recordIndex < this.manualImportModuleContentsRecords.length
      ) {
        let nextModuleType =
          this.manualImportModuleContentsRecords[recordIndex].module_type;
        let nextColumn =
          this.manualImportModuleContentsHeaders[columnIndex].value;

        this.selectedCell = `${nextModuleType}${this.ANT_DELIMITER()}${nextColumn}`;

        this.cellValue = this.getCellValue(nextModuleType, nextColumn);
        this.currentCellValue = this.cellValue;
      }
    },

    selectCell(header, item) {
      if (this.deleteMode) {
        this.createOrUpdateRecord(item.module_type, header.value, null);
      } else {
        this.selectedCell = `${item.module_type}${ANT_DELIMITER}${header.value}`;
        this.cellValue = this.getCellValue(item.module_type, header.value);
        this.currentCellValue = this.cellValue;
      }
    },
    getModuleCountByType(type) {
      return this.modules.filter((item) => item.module_type === type).length;
    },
    getCellValue(moduleType, column) {
      let record = this.moduleContents.find(
        (item) =>
          item.module_type === moduleType && item.element_position === column
      );

      return record?.element_type;
    },
    resetCell(moduleType, column) {
      let record = this.moduleContents.find(
        (item) =>
          item.module_type === moduleType && item.element_position === column
      );
      if (record) {
        record[column] = this.cellValue;
      }
      this.selectedCell = null;
      this.cellValue = null;
      this.currentCellValue = null;
    },

    async createOrUpdateRecord(moduleType, column, value) {
      const cellKey = `${moduleType}_${column}`;

      if (this.lockedCells.has(cellKey)) {
        return;
      }

      this.lockedCells.add(cellKey);
      this.isLoading = true;

      try {
        const record = this.moduleContents.find(
          (item) =>
            item.module_type === moduleType && item.element_position === column
        );

        let body = {
          module_type: moduleType,
          element_type: value,
          element_position: column,
        };

        if (record) {
          record.element_type = value;
          await this.$store.dispatch(
            'dhmeManualImportStore/manualImportUpdateModuleContentsRecord',
            { recordId: record.id, recordBody: body }
          );
        } else {
          await this.$store.dispatch(
            'dhmeManualImportStore/manualImportCreateModuleContentsRecord',
            { recordBody: body }
          );
        }
      } catch (e) {
        console.log(e);
        this.$store.commit('showNotification', {
          content: 'something went wrong',
          color: 'error',
        });
        this.resetCell(moduleType, column);
      } finally {
        this.isLoading = false;
        this.lockedCells.delete(cellKey);
      }
    },

    async generateElements() {
      const createdAt = this.lastElementGeneration?.datetime
        ? moment.unix(this.lastElementGeneration?.datetime)
        : null;
      if (createdAt !== null) {
        if (moment().diff(createdAt, 'minutes') < 15) {
          this.$store.commit('showNotification', {
            content:
              'Last generation was within 15 minutes, please try again later',
            color: 'warning',
          });
          return;
        }
      }

      try {
        this.isGenerating = true;
        let response = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'generateElements',
          {}
        );
        this.$store.commit('showNotification', {
          content: response.message,
          color: 'info',
        });
        await this.$store.dispatch(
          'dhmeManualImportStore/setTemporaryGenerationRecord',
          'elements'
        );
      } catch (e) {
        console.log(e);
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.isGenerating = false;
      }
    },
    exportToCsv() {
      let data = [...this.manualImportModuleContentsRecords];

      data.forEach((item) => {
        let headers = this.manualImportModuleContentsHeaders
          .filter((item) => item.input)
          .map((item) => item.value);
        headers.forEach((header) => {
          item[header] = this.getCellValue(item.module_type, header);
        });
      });

      FileHandlerService.convertJSONtoCSVandDownload(
        data,
        'manual_import_element_types_export'
      );
    },
    async importCsvData() {
      if (this.csvImportFile.name.split('.').pop() !== 'csv') {
        this.$store.commit('showNotification', {
          content: 'Only CSV files are accepted',
          color: 'error',
        });
        this.csvImportFile = undefined;
      } else {
        let file = await FileHandlerService.handleFile(this.csvImportFile);
        let data = FileHandlerService.convertBase64CSVtoJSON(file.data);
        let importData = [];
        data.forEach((item) => {
          let headers = this.manualImportModuleContentsHeaders
            .filter((item) => item.input)
            .map((item) => item.value);
          headers.forEach((header) => {
            let record = this.moduleContentsTable.find(
              (registerItem) =>
                registerItem.module_type === item.module_type &&
                registerItem.element_position === header
            );

            importData.push({
              id: record?.id ?? null,
              module_type: item.module_type,
              element_type: item[header],
              element_position: header,
            });
          });
        });

        let body = {
          records: FileHandlerService.convertToBase64(
            FileHandlerService.convertJSONtoCSV(importData)
          ),
        };

        this.$store
          .dispatch('importRecords', {
            tableId: this.moduleContentsTable,
            data: body,
          })
          .then(() => {
            this.csvImportFile = undefined;

            this.$store.dispatch('dhmeManualImportStore/fetchModuleContents');
          });
      }
    },
    setTableOptions(options) {
      this.tableOptions = options;
    },
  },
});
</script>

<template>
  <dynamic-data-table
    ref="moduleContents"
    table-title="Elements"
    :table-records="manualImportModuleContentsRecords"
    :table-headers="manualImportModuleContentsHeaders"
    class="flex-grow-1 ant-glass-background overflow-x-auto"
    :is-loading="isLoading"
    has-options
    :use-default-export="false"
    @scroll="handleScroll"
    @onOptionsUpdate="setTableOptions"
  >
    <template #body.prepend>
      <transition name="slide-right">
        <tbody
          v-if="showOverlay"
          class="overlay"
          :style="{ height: `${overlayHeight}px` }"
        >
          <tr
            v-for="record in overlayRecords"
            :key="`overlay_${record.module_type}`"
            :class="{
              'focused-row': focusedRowModuleType === record.module_type,
            }"
            :style="{
              height:
                focusedRowModuleType === record.module_type ? '40px' : '32px',
            }"
          >
            <td class="px-2">
              {{ getModuleCountByType(record.module_type) }}
            </td>
            <td class="px-2 ant-border-left">{{ record.module_type }}</td>
          </tr>
        </tbody>
      </transition>
    </template>
    <template #header.count="{ header }">
      Count ({{ modules.length }})
    </template>
    <template
      v-for="tmp in manualImportModuleContentsHeaders.filter((y) => y.input)"
      #[`header.${tmp.value}`]="{ header }"
    >
      {{ tmp.text }}
      <v-icon
        v-if="editTableHeaders"
        :key="tmp.value"
        @click="tmp.shown = !tmp.shown"
        >{{ tmp.shown ? 'mdi-eye' : 'mdi-eye-off-outline' }}</v-icon
      >
    </template>
    <template #table-buttons>
      <v-btn-toggle
        v-model="columnTab"
        mandatory
        tile
        color="primary"
        group
        class="mx-2"
      >
        <v-btn value="hsb" small> HSB </v-btn>

        <v-btn value="koz" small> KOZIJNEN </v-btn>

        <v-btn value="overig" small> OVERIG </v-btn>
      </v-btn-toggle>
      <v-text-field
        v-model="moduleTypeSearchValue"
        prepend-inner-icon="mdi-magnify"
        placeholder="Search..."
        hide-details
        dense
        filled
        single-line
        clearable
        class="normal-text-field"
      />

      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2"
            :color="editTableHeaders ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
            @click="editTableHeaders = !editTableHeaders"
          >
            mdi-table-edit
          </v-icon>
        </template>
        <span>hide/unhide columns</span>
      </v-tooltip>

      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2"
            :color="deleteMode ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
            @click="deleteMode = !deleteMode"
          >
            {{ deleteMode ? 'mdi-delete-empty' : 'mdi-delete-empty-outline' }}
          </v-icon>
        </template>
        <span>Toggle delete mode (Ctrl+Alt+D)</span>
      </v-tooltip>

      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2"
            :color="ctrlAltDown ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
          >
            mdi-arrow-all
          </v-icon>
        </template>
        <span
          >You can use Ctrl+Alt+Arrow keys to move from cell to cell when a cell
          is selected</span
        >
      </v-tooltip>
    </template>
    <template #table-options-menu>
      <v-list-item @click.stop="$refs['csv-import'].$refs.input.click()">
        <v-list-item-icon style="margin-right: 10px">
          <v-icon dense> mdi-file-import</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Import CSV</v-list-item-title>
        <v-file-input
          ref="csv-import"
          v-model="csvImportFile"
          accept=".csv"
          style="display: none"
          @change="importCsvData()"
        />
      </v-list-item>
      <v-list-item @click="exportToCsv">
        <v-list-item-icon style="margin-right: 10px">
          <v-icon dense> mdi-file-download </v-icon>
        </v-list-item-icon>
        <v-list-item-title> Export to CSV </v-list-item-title>
      </v-list-item>
    </template>
    <template #table-actions>
      <v-chip v-if="deleteMode" color="warning" class="mr-2"
        ><v-icon>mdi-exclamation-thick</v-icon>DELETE MODE ACTIVE</v-chip
      >
      <div
        v-if="lastElementGeneration"
        class="d-flex flex-column mx-2 fs-12 align-end"
      >
        <div>Last generated</div>
        <div>
          <span class="font-weight-bold">{{ lastElementGeneration.user }}</span>
          -
          {{ getDateFromTimestamp(lastElementGeneration.datetime) }}
        </div>
      </div>
      <v-btn
        color="primary"
        elevation="0"
        small
        :disabled="isGenerating"
        :loading="isGenerating"
        @click="generateElements"
        >Generate</v-btn
      >
    </template>
    <template #item.count="{ value, rowId, item }">
      <td
        :class="{ 'focused-row': focusedRowModuleType === item.module_type }"
        class="text-center"
      >
        {{ getModuleCountByType(item.module_type) }}
      </td>
    </template>
    <template #item.module_type="{ value, item }">
      <td
        :class="{ 'focused-row': focusedRowModuleType === item.module_type }"
        class="text-center"
      >
        {{ value }}
      </td>
    </template>
    <template
      v-for="header in manualImportModuleContentsHeaders.filter((x) => x.input)"
      #[`item.${header.value}`]="{ value, rowId, item }"
    >
      <td :key="header.value" :class="header.class">
        <v-text-field
          v-if="
            selectedCell ===
            `${item.module_type}${ANT_DELIMITER()}${header.value}`
          "
          v-model="currentCellValue"
          dense
          single-line
          hide-details
          filled
          autofocus
          class="small-text-field"
          :disabled="lockedCells.has(`${item.module_type}_${header.value}`)"
          @keydown.enter="updateAndRowDown"
          @keydown.esc="resetCell(item.module_type, header.value)"
        />

        <div
          v-else
          class="full-height full-width d-flex align-center justify-center"
          :class="{ 'focused-row': focusedRowModuleType === item.module_type }"
          @click="selectCell(header, item)"
        >
          {{ getCellValue(item.module_type, header.value) }}
        </div>
      </td>
    </template>
  </dynamic-data-table>
</template>

<style scoped lang="scss">
td {
  padding: 0 !important;
}
::v-deep .v-text-field__details {
  height: 0;
  overflow: hidden;
  min-height: 0;
}

::v-deep .x-small-text-field {
  max-width: 100px !important;
}

::v-deep .small-text-field {
  max-width: 150px !important;
}

::v-deep .normal-text-field {
  max-width: 200px !important;
}

::v-deep .v-data-table__wrapper {
  overflow-x: auto;
  position: relative;
}

.overlay {
  overflow: hidden;
  position: fixed;
  left: 0;
  background-color: white; /* Example background color */
  z-index: 2;
  border-right: solid 2px darkgrey;

  tr {
    box-sizing: border-box;
    border-bottom: solid 1px lightgray;
    display: flex;

    td {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 72px;
      font-size: 0.875rem;
    }
  }
}

.focused-row {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>

<template>
  <div class="d-flex flex-1">
    <div class="d-flex flex-column flex-1 ant-border-right pa-4">
      <v-subheader style="height: auto; font-size: 16px" class="pa-0 mb-4"
      >Options & Remarks
        <v-spacer />
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on" @click="closeDetailView"> mdi-close</v-icon>
          </template>
          <span>Close view</span>
        </v-tooltip>
      </v-subheader>

      <div v-if="!loading" class="overflow-y-auto">
        <div
            v-for="location in assemblyPreparationsHallLocations"
            :key="location.id"
            class="px-2 py-4 ant-border-bottom"
        >
          <v-subheader
              v-if="assemblyPreparationsHall.automated"
              class="pa-0 mb-2"
              style="height: auto"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                    class="mr-2"
                    :color="isLocationCompleted(location) ? 'success' : ''"
                    :disabled="isLocationCompleted(location)"
                    v-on="on"
                    @click="completeLocation(location)"
                >
                  {{
                    isLocationCompleted(location)
                        ? 'mdi-check-circle-outline'
                        : 'mdi-circle-outline'
                  }}
                </v-icon>
              </template>
              <span
              >{{
                  isLocationCompleted(location)
                      ? 'Location completed'
                      : 'Complete location'
                }}
              </span>
            </v-tooltip>
            Location {{ location.assembly_location }} -
            {{ location.description }}
            <v-spacer />
            <v-checkbox
                v-if="!isLocationCompleted(location)"
                v-model="location.toggle"
                hide-details
                class="mt-0 pt-0"
                label="Select all"
                :ripple="false"
                @click="selectAll(location)"
            />
          </v-subheader>
          <v-subheader v-else class="pa-0 mb-2" style="height: auto">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                    class="mr-2"
                    :color="isLocationCompleted(location) ? 'success' : ''"
                    v-on="on"
                    @click="completeLocation(location)"
                >
                  {{
                    isLocationCompleted(location)
                        ? 'mdi-check-circle-outline'
                        : 'mdi-circle-outline'
                  }}
                </v-icon>
              </template>
              <span
              >{{
                  isLocationCompleted(location)
                      ? 'Location completed'
                      : 'Complete location'
                }}
              </span>
            </v-tooltip>
            Location
            <v-spacer />
            <v-checkbox
                v-if="!isLocationCompleted(location)"
                v-model="location.toggle"
                hide-details
                class="mt-0 pt-0"
                label="Select all"
                :ripple="false"
                @click="selectAll(location)"
            />
          </v-subheader>
          <div class="d-flex">
            <div class="flex-1 mr-4">
              <div
                  v-for="step in assemblyLocationSteps(location)"
                  :key="`${location.id}-step-${step.id}`"
              >
                <v-chip small class="my-2"
                >{{ step.order }} - {{ step.title }}</v-chip
                >
                <div
                    v-for="(item, index) in locationActivities[
                    location.id
                  ].activities
                    .filter((a) => a.step === step.id)
                    .sort((a, b) => a.order - b.order)"
                    :key="index"
                    class="d-flex align-center fs-12"
                >
                  <v-menu
                      v-if="item.deviation"
                      v-model="deviationOrderMenus[item.id]"
                      :close-on-content-click="false"
                      :nudge-width="200"
                      offset-x
                  >
                    <template #activator="{ on, attrs }">
                      <span
                          class="fs-10 font-italic d-flex text-primary c-pointer"
                          style="width: 30px"
                          v-on="on"
                      >
                        {{ item.order }}
                      </span>
                    </template>

                    <v-card class="pa-2">
                      <ant-input label="Order" top-margin="mt-0">
                        <template #input-field>
                          <v-text-field
                              v-model="item.order"
                              placeholder="0-100"
                              hide-details
                              single-line
                              filled
                              dense
                              autofocus
                              type="number"
                              @keydown.enter="updateOrderToDeviationTask(item)"
                          />
                        </template>
                      </ant-input>
                    </v-card>
                  </v-menu>
                  <span
                      v-else
                      class="fs-10 font-italic d-flex"
                      style="width: 30px"
                  >
                    {{ item.order }}
                  </span>
                  <v-checkbox
                      :id="item.id"
                      :ref="item.id"
                      v-model="item.selected"
                      :ripple="false"
                      dense
                      :disabled="isLocationCompleted(location)"
                      class="mt-0 pt-0"
                      hide-details
                  ></v-checkbox>
                  <v-icon
                      v-if="item.deviation"
                      small
                      color="warning"
                      class="mr-2"
                  >mdi-alert-rhombus-outline
                  </v-icon>
                  {{ item.activity }}
                  <span v-if="item.position" class="font-italic ml-2 fs-10">{{
                      item.position
                    }}</span>
                  <v-spacer />
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{
                          item?.leanforms_q_nr
                        }}</span>
                    </template>
                    <span>{{ item.leanforms_remaining_point ?? '-' }}</span>
                  </v-tooltip>
                </div>
                <div
                    v-if="!isLocationCompleted(location)"
                    class="d-flex align-center"
                >
                  <v-checkbox dense hide-details disabled class="mt-0 pt-0" />
                  <v-menu
                      v-model="
                      locationDeviationMenus[`${location.id}-${step.id}`]
                    "
                      :close-on-content-click="false"
                      :nudge-width="200"
                      offset-x
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs" dense :ripple="false" v-on="on"
                      >mdi-plus
                      </v-icon>
                    </template>

                    <v-card class="pa-2">
                      <ant-input label="Option" top-margin="mt-0">
                        <template #input-field>
                          <v-text-field
                              v-model="locationDeviations[location.id]"
                              placeholder="title"
                              hide-details
                              autofocus
                              single-line
                              dense
                              filled
                              @keydown.enter="
                              addLocationDeviation(location, step)
                            "
                          />
                        </template>
                      </ant-input>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex align-center justify-center flex-1">
        <ant-loading />
      </div>
    </div>
    <div class="d-flex flex-column flex-1">
      <div class="flex-1 ant-glass-background radius-0">
        <v-subheader>Elements</v-subheader>
        <v-data-table
            style="background-color: transparent !important"
            :loading="loading"
            :headers="elementHeaders"
            :items="elements"
        ></v-data-table>
      </div>
      <div class="d-flex align-center justify-center flex-1 ant-border-top">
        <iframe
            :src="documentPreview"
            type="application/pdf"
            height="100%"
            width="100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DHME_ASSEMBLY_PREPARATIONS } from '@/modules/modules';
import { executeCustomModuleCall } from '@/services/api/module.api';
import AntLoading from '@/components/AntLoading.vue';
import DHMEASMixin from '@/modules/daiwa-house-modular-europe/AssemblyPreparations/DhmeAssemblyPreparations.mixin';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'DhmeAssemblyPreparationsModuleDetails',
  components: { AntInput, AntLoading },
  mixins: [DHMEASMixin],
  props: {
    module: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      elementHeaders: [
        {
          text: 'code',
          value: 'element_type',
        },
        {
          text: 'type',
          value: 'element_category',
        },
      ],
      selectedActivities: {},
      locationTasks: [],
      elements: [],
      loading: true,
      deviationOrderMenus: {},
      locationActivities: {},
      locationDeviationMenus: {},
      locationDeviations: {},
      documentPreview: null,
    };
  },
  computed: {
    ...mapGetters([
      'assemblyPreparationsHall',
      'assemblyPreparationsHallLocations',
      'assemblyPreparationLocationActivities',
      'assemblyPreparationsElements',
      'assemblyPreparationsLocationStatuses',
      'assemblyConfigurationSteps',
      'project',
      'assemblyPreparationLocationTypes',
      'assemblyPreparationsModuleAssemblyTasks',
    ]),

    locationStatuses() {
      return this.assemblyPreparationsLocationStatuses.filter(
          (item) => item.module_id === this.module.module_id
      );
    },

    moduleId() {
      return this.project.modules.find(
          (module) => module.route === DHME_ASSEMBLY_PREPARATIONS
      ).id;
    },

    moduleAssemblyTask() {
      return this.assemblyPreparationsModuleAssemblyTasks.find(
          (t) => t.sbscode.code === this.module.build_nr
      );
    },
  },
  watch: {
    moduleAssemblyTask: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.fetch2DDrawing(value, this.project.id, this.module.module_id);
        }
      },
    },
  },
  async mounted() {
    await this.fetchTasks();

    this.elements = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'fetchModuleElements',
        {
          module: this.module.module_id,
        }
    );

    this.assemblyPreparationsHallLocations.forEach((location) => {
      this.locationActivities[location.id] = {
        activities: [],
      };

      this.getActivitiesByLocation(
          location,
          this.elements,
          this.assemblyPreparationLocationTypes,
          this.assemblyPreparationLocationActivities
      ).forEach((activity) => {
        if (activity.type && activity.position) {
          let element = this.elements.find(
              (e) =>
                  e.module_id === this.module.module_id &&
                  e.element_type === activity.type &&
                  e.element_position === activity.position
          );
          activity.id = element.element_id;
          activity.sbscode = element.element_id;
        }
        activity.deviation = false;
        activity.selected =
            this.locationTasks.findIndex(
                (task) =>
                    task.task_type.custom_2 === location.id &&
                    task.task_type.custom_3 === activity.id
            ) !== -1;
        this.locationActivities[location.id].activities.push(activity);
      });
      let deviationTasks = this.locationTasks.filter(
          (task) =>
              task.task_type.custom_2 === location.id &&
              task.task_type.custom_3 === null
      );
      deviationTasks.forEach((task) => {
        this.locationActivities[location.id].activities.push({
          id: null,
          assembly_location: location.id,
          deviation: true,
          selected: true,
          activity: task.title,
        });
      });
    });
    this.loading = false;
  },
  methods: {
    selectAll(location) {
      this.locationActivities[location.id].activities.map((item) => {
        item.selected = location.toggle;
        return item;
      });
    },
    assemblyLocationSteps(location) {
      return this.assemblyConfigurationSteps
          .filter((s) =>
              Array.from(
                  new Set(
                      this.locationActivities[location.id].activities.map((a) => a.step)
                  )
              ).includes(s.id)
          )
          .sort((a, b) => parseInt(a.order) - parseInt(b.order));
    },
    updateOrderToDeviationTask(activity) {
      activity.order = parseInt(activity.order);
      this.deviationOrderMenus[activity.id] = false;
    },
    addLocationDeviation(location, step) {
      this.locationActivities[location.id].activities.push({
        id: null,
        assembly_location: location.id,
        deviation: true,
        selected: true,
        activity: this.locationDeviations[location.id],
        order: 99,
        step: step.id,
      });
      this.locationDeviations[location.id] = null;
      this.locationDeviationMenus[`${location.id}-${step.id}`] = false;
    },
    async fetchTasks() {
      this.locationTasks = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'fetchAssemblyPrepTasks',
          {
            module: this.module.module_id,
          }
      );
    },

    isLocationCompleted(location) {
      return (
          this.locationStatuses.find(
              (item) => item.assembly_location === location.id
          ) !== undefined
      );
    },
    closeDetailView() {
      this.documentPreview = null;
      this.$emit('closeView');
    },

    async completeLocation(location) {
      let selectedActivities = this.locationActivities[
          location.id
          ].activities.filter((a) => a.selected);

      let tasks = selectedActivities.map((a) => {
        return {
          title: a.activity,
          parent: this.moduleAssemblyTask.id,
          due: this.moduleAssemblyTask.due,
          sbscode: a.sbscode ?? null,
          project: this.project.id,
          license: this.project.license,
          options: {
            type: 'dhme-module-assembly-station',
            custom_1: this.module.module_id,
            custom_2: this.moduleAssemblyTask.task_type.custom_3 ?? location.id,
            custom_3: a.id,
            custom_4: a.position ?? null,
            custom_5: a.order ?? 0,
            custom_6: a.step,
            custom_7: a.leanforms_q_nr ?? null,
            custom_8: a.leanforms_remaining_point ?? null,
            custom_9: a.btn_options ?? null,
            metadata: a.translations ?? null,
          },
        };
      });

      await this.$store.dispatch('importAssemblyPreparationsTasks', tasks);
      await this.$store.dispatch('setLocationStatus', {
        assembly_location: location.id,
        module_id: this.module.module_id,
        status: selectedActivities.some((t) => t.id === null)
            ? 'alert'
            : 'success',
      });
    },
  },
};
</script>

<style scoped></style>

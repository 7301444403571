<template>
  <div v-if="wkbInspectionPlanRiskDetail" class="d-flex flex-column flex-1">
    <div
      class="ant-glass-background radius-0 ant-border-bottom d-flex flex-column"
    >
      <div
        class="d-flex align-center px-5 py-2 white--text"
        :style="{
          'background-color': getSubChapterStateColor(
            getSubChapterState(
              selectedSubchapter.chapter,
              selectedSubchapter.sub_chapter
            )
          ),
        }"
      >
        {{ selectedSubchapter.chapter }}.{{ selectedSubchapter.sub_chapter }}
        <v-divider vertical class="mx-2" />
        {{ selectedSubchapter.title }}
        <v-spacer />
        <v-chip small>
          {{
            getSubChapterState(
              selectedSubchapter.chapter,
              selectedSubchapter.sub_chapter
            )
              ? getSubChapterState(
                  selectedSubchapter.chapter,
                  selectedSubchapter.sub_chapter
                )
              : 'Unknown'
          }}
        </v-chip>
      </div>
      <dynamic-data-table
        table-title="Onderbouwingen"
        :table-records="chapterSubstantiations"
        :table-headers="substantiationsHeaders"
        style="background-color: white"
        :table-height="`${tableHeight}px`"
      >
        <template #item.documents="{ value, item, rowId }">
          <div class="d-flex flex-column py-1">
            <v-chip
              v-for="document in getSubstantiationDocuments(item.id)"
              :key="document.id"
              class="mb-1"
              small
              @click="downloadDocument(document)"
              >{{ document.file.name }}.{{ document.file.extension }}
            </v-chip>
          </div>
        </template>
      </dynamic-data-table>
      <resize-vertical @size="resizeTableHeight" />
      <div
        class="d-flex align-center px-5 py-2 white--text"
        :style="{ 'background-color': getRiskColor(projectRisk.risk_lvl) }"
      >
        {{ wkbInspectionPlanRiskDetail.chapter }}.{{
          wkbInspectionPlanRiskDetail.sub_chapter
        }}.{{ wkbInspectionPlanRiskDetail.friendly_id }}
        <v-divider vertical class="mx-2" />
        {{ wkbInspectionPlanRiskDetail.name }}
        <v-spacer />
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-chip small v-bind="attrs" v-on="on">
              {{ getRiskText }}
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="lvl in riskOptions"
              :key="lvl.value"
              @click="updateRiskLevel(lvl.value)"
            >
              <v-chip :color="getRiskColor(lvl.value)" class="white--text"
                >{{ lvl.text }}
              </v-chip>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="px-3 py-2 overflow-y-auto">
      <v-subheader class="pa-0" style="height: auto">Maatregelen</v-subheader>
      <div
        v-for="measurement in mappedMeasurements"
        :key="measurement.id"
        class="d-flex align-center py-1 my-1 fs-12"
        :class="{
          'alert-measurement':
            isMeasurementMappedToRisk(measurement) &&
            getMeasurementPercentileCount(measurement) === 0,
        }"
      >
        <v-simple-checkbox
          dense
          hide-details
          :ripple="false"
          :value="isMeasurementMappedToRisk(measurement)"
          class="shrink mt-0 pt-0"
          @click="toggleProjectMeasurementToRisk(measurement)"
        />
        <div style="min-width: 20px">
          {{ measurement.number ? measurement.number : '-' }}
        </div>
        <div class="font-italic" style="width: 80px">
          {{ measurement.type }}
        </div>
        <v-icon
          v-if="
            isMeasurementMappedToRisk(measurement) &&
            getMeasurementPercentileCount(measurement) === 0
          "
          class="mr-2"
          color="error"
          >mdi-alert</v-icon
        >
        {{ measurement.name }}
        <v-spacer />
        <dhme-wkb-phase-chip
          v-for="(phase, index) in getMeasurementPhases(measurement)"
          :key="`${measurement.id}_${phase}_${index}`"
          :text="phase?.substring(5)"
          :type="phase"
        />

        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-chip color="primary" small class="mx-2" v-on="on"
              >{{ measurement.party ? measurement.party : '-' }}
            </v-chip>
          </template>
          <span>Uitvoerende partij</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              :disabled="!isMeasurementMappedToRisk(measurement)"
              v-on="on"
              >mdi-percent-circle
            </v-icon>
          </template>
          <span>Steekproef grootte</span>
        </v-tooltip>

        <div style="min-width: 30px">
          <v-menu
            v-if="isMeasurementMappedToRisk(measurement)"
            v-model="sampleSizeMenus[measurement.id]"
            offset-x
            :close-on-content-click="false"
            :disabled="measurement.type === 'document'"
          >
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="mx-2"
                v-on="on"
                @click="
                  updatedSampleSize =
                    getProjectMeasurementRisk(measurement).sample_size
                "
              >
                {{
                  measurement.type === 'leanform'
                    ? getProjectMeasurementRisk(measurement).sample_size
                      ? `${getProjectMeasurementRisk(measurement).sample_size}%`
                      : '-'
                    : '100%'
                }}
              </div>
            </template>
            <div class="d-flex flex-column pa-2 ant-glass-background">
              <v-subheader
                class="font-weight-bold pa-0"
                style="font-size: 11px; height: auto"
              >
                Sample size
              </v-subheader>
              <v-text-field
                v-model="updatedSampleSize"
                autofocus
                type="number"
                :min="0"
                :max="100"
                placeholder="Give sample size"
                @keydown.enter="updateSampleSize(measurement)"
              />
            </div>
          </v-menu>
        </div>
        <div style="min-width: 100px">
          {{ getMeasurementPercentileCount(measurement) }} -
          {{ measurement.sample_level }}
          ({{ getMeasurementLevelCount(measurement) }})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import FileHandlerService from '@/services/file-handler';
import ResizeVertical from '@/components/ResizeVertical.vue';
import DhmeWkbPhaseChip from '@/modules/daiwa-house-modular-europe/DhmeWkbPhaseChip.vue';

export default {
  name: 'DhmeWkbInspectionPlanRisksDetail',
  components: { DhmeWkbPhaseChip, ResizeVertical, DynamicDataTable, AntInput },
  data: () => {
    return {
      tableHeight: 300, // Initial table height
      reasonMenu: false,
      commentMenu: false,
      sampleSizeMenus: [],
      updatedReason: null,
      updatedComment: null,
      updatedSampleSize: null,
      riskOptions: [
        {
          text: '0-afgehandeld',
          value: 0,
        },
        {
          text: '1-gering',
          value: 1,
        },
        {
          text: '2-mogelijk',
          value: 2,
        },
        {
          text: '3-risico',
          value: 3,
        },
        {
          text: '4-n.v.t.',
          value: 4,
        },
      ],
      substantiationsHeaders: [
        {
          text: 'Beschrijving',
          value: 'description',
        },
        {
          text: 'Gebruiker',
          value: 'user',
        },
        {
          text: 'Documenten',
          value: 'documents',
          hasSlot: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'wkbInspectionPlanModules',
      'wkbInspectionPlanRiskDetail',
      'wkbInspectionPlanRiskDetailChapter',
      'wkbInspectionPlanChapters',
      'wkbInspectionPlanProjectRisks',
      'wkbInspectionPlanMeasurements',
      'wkbInspectionPlanProjectRisksMeasures',
      'wkbInspectionPlanMeasurementForms',
      'wkbInspectionPlanMeasurementDocuments',
      'wkbInspectionPlanRiskMapping',
      'wkbRiskIPStatuses',
      'wkbInspectionPlanChapterSubstantiations',
      'wkbInspectionPlanChapterSubstantiationDocuments',
      'wkbInspectionPlanChapterSubstantiationDocumentsTableId',
    ]),
    selectedSubchapter() {
      return this.wkbInspectionPlanRiskDetailChapter;
    },
    mappedMeasurements() {
      return this.wkbInspectionPlanMeasurements.filter(
        (measurement) =>
          this.wkbInspectionPlanRiskMapping.findIndex(
            (relation) =>
              relation.risk_id === this.wkbInspectionPlanRiskDetail.id &&
              relation.measurement_id === measurement.id
          ) !== -1
      );
    },
    projectRisk() {
      return this.wkbInspectionPlanProjectRisks.find(
        (item) => item.risk_id === this.wkbInspectionPlanRiskDetail.id
      );
    },
    chapterSubstantiations() {
      return this.wkbInspectionPlanChapterSubstantiations.filter(
        (record) =>
          record.chapter ===
          this.wkbInspectionPlanChapters.find(
            (item) =>
              item.chapter === this.wkbInspectionPlanRiskDetail.chapter &&
              item.sub_chapter === this.wkbInspectionPlanRiskDetail.sub_chapter
          ).id
      );
    },
    getRiskText() {
      switch (this.projectRisk.risk_lvl) {
        case 0:
          return 'afgehandeld';
        case 1:
          return 'gering';
        case 2:
          return 'mogelijk';
        case 3:
          return 'risico';
        case 4:
          return 'n.v.t.';
        default:
          return '-';
      }
    },
  },
  methods: {
    resizeTableHeight(newHeight) {
      // Ensure a minimum height for the table
      this.tableHeight = Math.max(100, newHeight - 200);
    },
    getSubChapterState(chapter, subchapter) {
      return this.wkbRiskIPStatuses.find(
        (record) => record.chapter === `${chapter}.${subchapter}`
      )?.status;
    },
    getSubChapterStateColor(type) {
      switch (type) {
        case 'afgehandeld':
          return '#66bb6a';
        case 'gering':
          return '#d8d827';
        case 'mogelijk':
          return '#ffc107';
        case 'risico':
          return '#ef5350';
        case 'n.v.t.':
          return '#424242';
        default:
          return '#7d7d7d';
      }
    },
    getSubstantiationDocuments(substantiationId) {
      return this.wkbInspectionPlanChapterSubstantiationDocuments.filter(
        (record) => record.substantiation === substantiationId
      );
    },
    downloadDocument(item) {
      FileHandlerService.downloadRecordDocument(
        item.file.id,
        this.project.id,
        this.wkbInspectionPlanChapterSubstantiationDocumentsTableId
      );
    },
    getMeasurementPercentileCount(measurement) {
      let projectMeasurement = this.wkbInspectionPlanProjectRisksMeasures.find(
        (prm) =>
          prm.measurement_id === measurement.id &&
          prm.risk_id === this.wkbInspectionPlanRiskDetail.id
      );
      let total = this.getMeasurementLevelCount(measurement);

      return Math.ceil(
        (total *
          (measurement.type === 'document'
            ? 100
            : (projectMeasurement?.sample_size ??
              measurement.default_sample_size))) /
          100
      );
    },
    getMeasurementLevelCount(measurement) {
      switch (measurement.sample_level) {
        case 'Project':
          return 1;
        case 'Modules':
          return this.wkbInspectionPlanModules.length;
        case 'Bouwblokken':
          let buildBlocks = [
            ...new Set(
              this.wkbInspectionPlanModules
                .filter((module) => module.build_nr)
                .map((module) => module?.build_nr?.charAt(0))
            ),
          ];
          return buildBlocks.length;
        case 'Types':
          let moduleTypes = [
            ...new Set(
              this.wkbInspectionPlanModules.map((module) => module.module_type)
            ),
          ];

          return moduleTypes.length;
        case 'Bouwlagen':
          let buildLayers = [
            ...new Set(
              this.wkbInspectionPlanModules.map((module) =>
                module?.build_nr?.substring(0, 2)
              )
            ),
          ];

          return buildLayers.length;
      }
    },
    getRiskColor(level) {
      switch (level) {
        case 0:
          return '#66bb6a';
        case 1:
          return '#d8d827';
        case 2:
          return '#ffc107';
        case 3:
          return '#ef5350';
        case 4:
          return '#424242';
        default:
          return '#424242';
      }
    },
    getMeasurementPhases(measurement) {
      if (measurement.type === 'leanform') {
        return this.wkbInspectionPlanMeasurementForms
          .filter((item) => item.measurement_id === measurement.id)
          .map((item) => item.phase);
      } else if (measurement.type === 'document') {
        return this.wkbInspectionPlanMeasurementDocuments
          .filter((item) => item.measurement_id === measurement.id)
          .map((item) => item.phase);
      }
    },
    getProjectMeasurementRisk(measurement) {
      return this.wkbInspectionPlanProjectRisksMeasures.find(
        (relation) =>
          relation.risk_id === this.wkbInspectionPlanRiskDetail.id &&
          relation.measurement_id === measurement.id
      );
    },
    isMeasurementMappedToRisk(measurement) {
      return (
        this.wkbInspectionPlanProjectRisksMeasures.findIndex(
          (relation) =>
            relation.risk_id === this.wkbInspectionPlanRiskDetail.id &&
            relation.measurement_id === measurement.id
        ) !== -1
      );
    },
    toggleProjectMeasurementToRisk(measurement) {
      let bool = !this.isMeasurementMappedToRisk(measurement);
      if (bool) {
        // add measurement - risk mapping
        let body = {
          record: {
            risk_id: this.wkbInspectionPlanRiskDetail.id,
            measurement_id: measurement.id,
            sample_size:
              measurement.default_sample_size > 0
                ? measurement.default_sample_size
                : 0,
          },
        };
        this.$store.dispatch('addProjectRiskMeasurement', body);
      } else {
        // remove measurement - risk mapping
        let projectRiskMeasurement =
          this.wkbInspectionPlanProjectRisksMeasures.find(
            (relation) =>
              relation.risk_id === this.wkbInspectionPlanRiskDetail.id &&
              relation.measurement_id === measurement.id
          );
        this.$store.dispatch(
          'deleteProjectRiskMeasurement',
          projectRiskMeasurement.id
        );
      }
    },
    updateRiskLevel(level) {
      let body = {
        record: {
          risk_lvl: level,
          risk_id: this.projectRisk.risk_id,
        },
      };
      this.$store.dispatch('updateProjectRisk', {
        recordId: this.projectRisk.id,
        body,
      });
    },
    updateSampleSize(measurement) {
      let item = this.getProjectMeasurementRisk(measurement);
      let body = {
        record: {
          sample_size: this.updatedSampleSize,
        },
      };
      this.$store
        .dispatch('updateProjectRiskMeasurement', {
          recordId: item.id,
          body,
        })
        .then(() => {
          this.sampleSizeMenus[measurement.id] = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.alert-measurement {
  background-color: var(--v-error-lighten4);
}
</style>

<template>
  <v-dialog
      v-model="showDialog"
      width="unset"
      max-width="90%"
      @click:outside="closeDialog"
      @keydown.esc="closeDialog"
  >
    <div
        class="ant-glass-dialog px-10 py-5 d-flex flex-column pos-rel full-width"
        style="height: 600px"
    >
      <div class="d-flex flex-scroll-height">
        <div class="flex-1 d-flex flex-column">
          <ant-input label="Module Type" top-margin="mt-0">
            <template #input-field>
              <v-select
                  v-model="moduleType"
                  filled
                  :items="uniqueModuleTypes"
                  placeholder="Module type"
                  hide-details
                  dense
                  style="flex: 0"
              />
            </template>
          </ant-input>
          <div
              v-if="moduleType"
              class="d-flex flex-column flex-1 justify-start mt-5 overflow-y-auto"
          >
            <v-subheader
                class="font-weight-bold pa-0"
                style="font-size: 11px; height: auto"
            >
              Modules
            </v-subheader>
            <div class="flex-1 d-flex flex-column">
              <v-chip
                  v-for="module in modulesBySelectedType"
                  :key="module.id"
                  class="mr-2 my-2"
              >{{ module.module_id }}</v-chip
              >
            </div>
          </div>
        </div>
        <div v-if="moduleType" class="px-5 flex-1 overflow-y-auto">
          <div
              v-for="locationItem in locationActivities"
              :key="locationItem.id"
              class="px-2 py-4 ant-border-bottom"
          >
            <v-subheader
                v-if="assemblyPreparationsHall.automated"
                class="pa-0 mb-2"
                style="height: auto"
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      class="mr-2"
                      icon
                      :loading="locationItem.loading"
                      :disabled="locationItem.completed"
                      @click="completeLocation(locationItem)"
                      v-on="on"
                  >
                    <v-icon :color="locationItem.completed ? 'success' : ''">
                      {{
                        locationItem.completed
                            ? 'mdi-check-circle'
                            : 'mdi-circle-outline'
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Complete location</span>
              </v-tooltip>
              Location {{ locationItem.location.assembly_location }} -
              {{ locationItem.location.description }}
              <v-spacer />
              <v-checkbox
                  v-if="!locationItem.completed"
                  v-model="locationItem.toggle"
                  hide-details
                  class="mt-0 pt-0"
                  label="Select all"
                  :ripple="false"
                  @click="selectAll(locationItem)"
              />
            </v-subheader>
            <v-subheader v-else class="pa-0 mb-2" style="height: auto">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                      v-bind="attrs"
                      class="mr-2"
                      :color="
                      locationItem.enabled || locationItem.completed
                        ? 'success'
                        : ''
                    "
                      :disabled="locationItem.completed"
                      v-on="on"
                      @click="completeLocation(locationItem)"
                  >
                    {{
                      locationItem.enabled || locationItem.completed
                          ? 'mdi-check-circle'
                          : 'mdi-circle-outline'
                    }}
                  </v-icon>
                </template>
                <span>Complete location</span>
              </v-tooltip>
              Location
              <v-spacer />
              <v-checkbox
                  v-if="!locationItem.completed"
                  v-model="locationItem.toggle"
                  hide-details
                  class="mt-0 pt-0"
                  label="Select all"
                  :ripple="false"
                  @click="selectAll(locationItem)"
              />
            </v-subheader>
            <div v-if="!locationItem.completed" class="d-flex flex-column">
              <div
                  v-for="step in assemblyLocationSteps(locationItem)"
                  :key="`${locationItem.location.id}-step-${step.id}`"
              >
                <v-chip small class="my-2"
                >{{ step.order }} - {{ step.title }}</v-chip
                >
                <div class="flex-1">
                  <div
                      v-for="activityItem in locationItem.activities
                      .filter((a) => a.step === step.id)
                      .sort((a, b) => a.order - b.order)"
                      :key="activityItem.id"
                      class="d-flex align-center fs-12"
                  >
                    <v-menu
                        v-if="activityItem.deviation"
                        v-model="deviationOrderMenus[activityItem.id]"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-x
                    >
                      <template #activator="{ on, attrs }">
                        <span
                            class="fs-10 font-italic d-flex text-primary c-pointer"
                            style="width: 30px"
                            v-on="on"
                        >
                          {{ activityItem.order }}
                        </span>
                      </template>

                      <v-card class="pa-2">
                        <ant-input label="Order" top-margin="mt-0">
                          <template #input-field>
                            <v-text-field
                                v-model="activityItem.order"
                                placeholder="0-100"
                                hide-details
                                single-line
                                filled
                                dense
                                autofocus
                                type="number"
                                @keydown.enter="
                                updateOrderToDeviationTask(activityItem)
                              "
                            />
                          </template>
                        </ant-input>
                      </v-card>
                    </v-menu>
                    <span
                        v-else
                        class="fs-10 font-italic d-flex"
                        style="width: 30px"
                    >
                      {{ activityItem.order }}
                    </span>

                    <v-checkbox
                        v-model="activityItem.selected"
                        :ripple="false"
                        dense
                        class="mt-0 pt-0"
                        hide-details
                    ></v-checkbox>
                    <v-icon
                        v-if="activityItem.deviation"
                        small
                        color="warning"
                        class="mr-2"
                    >mdi-alert-rhombus-outline</v-icon
                    >
                    {{ activityItem.activity }}
                    <span
                        v-if="activityItem.position"
                        class="font-italic ml-2 fs-10"
                    >{{ activityItem.position }}</span
                    >
                    <v-spacer />
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{
                            activityItem?.leanforms_q_nr
                          }}</span>
                      </template>
                      <span>{{
                          activityItem.leanforms_remaining_point ?? '-'
                        }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <v-checkbox dense hide-details disabled class="mt-0 pt-0" />
                  <v-menu
                      v-model="
                      locationDeviationMenus[
                        `${locationItem.location.id}-${step.id}`
                      ]
                    "
                      :close-on-content-click="false"
                      :nudge-width="200"
                      offset-x
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs" dense :ripple="false" v-on="on"
                      >mdi-plus</v-icon
                      >
                    </template>

                    <v-card class="pa-2">
                      <ant-input label="Option" top-margin="mt-0">
                        <template #input-field>
                          <v-text-field
                              v-model="
                              locationDeviations[locationItem.location.id]
                            "
                              placeholder="title"
                              hide-details
                              autofocus
                              single-line
                              dense
                              filled
                              @keydown.enter="
                              addLocationDeviation(locationItem, step)
                            "
                          />
                        </template>
                      </ant-input>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            v-if="moduleType && documentPreview"
            class="d-flex align-center justify-center"
        >
          <iframe
              :src="documentPreview"
              type="application/pdf"
              height="100%"
              width="100%"
          />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { DHME_ASSEMBLY_PREPARATIONS } from '@/modules/modules';
import DHMEASMixin from '@/modules/daiwa-house-modular-europe/AssemblyPreparations/DhmeAssemblyPreparations.mixin';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'DhmeAssemblyPreparationsBatchDialog',
  components: { AntInput },
  mixins: [DHMEASMixin],
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      moduleType: null,
      locationActivities: [],
      deviationOrderMenus: {},
      locationDeviationMenus: {},
      locationDeviations: {},
      elements: [],
      importing: false,
      documentPreview: null,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'assemblyPreparationsModules',
      'assemblyPreparationsHall',
      'assemblyConfigurationSteps',
      'assemblyPreparationsHallLocations',
      'assemblyPreparationLocationActivities',
      'assemblyPreparationsLocationStatuses',
      'assemblyPreparationLocationTypes',
      'assemblyPreparationsModuleAssemblyTasks',
    ]),
    showDialog() {
      return this.displayed;
    },

    moduleId() {
      return this.project.modules.find(
          (module) => module.route === DHME_ASSEMBLY_PREPARATIONS
      ).id;
    },

    modulesBySelectedType() {
      if (this.moduleType) {
        return this.assemblyPreparationsModules.filter(
            (item) => item.module_type === this.moduleType
        );
      }
      return [];
    },

    uniqueModuleTypes() {
      return [
        ...new Set(
            this.assemblyPreparationsModules.map((item) => item.module_type)
        ),
      ].sort((a, b) => a.localeCompare(b));
    },
  },
  watch: {
    async moduleType(value) {
      if (value) {
        this.locationActivities = [];
        this.elements = await executeCustomModuleCall(
            this.project.id,
            this.moduleId,
            'fetchModuleTypeElements',
            {
              type: this.moduleType,
            }
        );

        this.assemblyPreparationsHallLocations.forEach((location) => {
          let object = {
            location: location,
            completed:
                this.modulesBySelectedType.filter(
                    (m) =>
                        !this.assemblyPreparationsLocationStatuses.find(
                            (s) =>
                                s.module_id === m.module_id &&
                                location.id === s.assembly_location
                        )
                ).length === 0,
            activities: [],
          };

          this.getActivitiesByLocation(
              location,
              this.elements,
              this.assemblyPreparationLocationTypes,
              this.assemblyPreparationLocationActivities
          ).forEach((activity) => {
            activity.deviation = false;
            activity.selected = false;
            if (
                !object.activities.some(
                    (a) =>
                        a.type &&
                        a.position &&
                        a.type === activity.type &&
                        a.position === activity.position
                )
            ) {
              object.activities.push(activity);
            }
          });
          this.locationActivities.push(object);
        });

        const task = this.assemblyPreparationsModuleAssemblyTasks.find((t) =>
            this.modulesBySelectedType.find((m) => {
              return (
                  m.build_nr === t.sbscode.code &&
                  t.appendixes.some((a) => a.name === `2D_${m.module_id}`)
              );
            })
        );
        if (task) {
          await this.fetch2DDrawing(
              task,
              this.project.id,
              this.modulesBySelectedType[0].module_id
          );
        }
      }
    },
  },
  methods: {
    selectAll(location) {
      location.activities.map((item) => {
        item.selected = location.toggle;
        return item;
      });
    },
    async completeLocation(locationItem) {
      locationItem.loading = true;
      let modules = this.modulesBySelectedType;
      let tasks = [];
      let locationStatuses = [];

      let selectedActivities = locationItem.activities.filter(
          (a) => a.selected
      );

      for (let i = 0; i < modules.length; i++) {
        let build_nr = modules[i].build_nr;
        let module_id = modules[i].module_id;
        if (
            this.assemblyPreparationsLocationStatuses.find(
                (s) =>
                    s.module_id === module_id &&
                    locationItem.location.id === s.assembly_location
            )
        ) {
          this.$store.commit('showNotification', {
            content: `Skip action for module '${module_id}'. This module already generated tasks on this location.`,
            color: 'info',
          });
          continue;
        }

        let tmpTasks = selectedActivities.map((a) => {
          const task = this.assemblyPreparationsModuleAssemblyTasks.find(
              (t) => t.sbscode.code === build_nr
          );
          const element = this.elements.find(
              (e) =>
                  e.module_id === module_id &&
                  e.element_type === a.type &&
                  e.element_position === a.position
          );
          return {
            title: a.activity,
            due: task.due,
            parent: task.id,
            sbscode: element ? element.element_id : null,
            project: this.project.id,
            license: this.project.license,
            options: {
              type: 'dhme-module-assembly-station',
              custom_1: module_id,
              custom_2: task.task_type.custom_3 ?? locationItem.location.id,
              custom_3: element ? element.element_id : a.id,
              custom_4: a.position ?? null,
              custom_5: a.order ?? 0,
              custom_6: a.step,
              custom_7: a.leanforms_q_nr ?? null,
              custom_8: a.leanforms_remaining_point ?? null,
              custom_9: a.btn_options ?? null,
              metadata: a.translations ?? null,
            },
          };
        });

        tasks = [...tasks, ...tmpTasks];

        locationStatuses.push({
          id: null,
          module_id: module_id,
          assembly_location: locationItem.location.id,
          status: selectedActivities.some((t) => t.id === null)
              ? 'alert'
              : 'success',
        });
      }

      const steps = [...new Set(tasks.map((x) => x.options.custom_6))];

      for (const step of steps) {
        let stepTasks = tasks.filter((t) => t.options.custom_6 === step);

        await this.$store.dispatch(
            'importAssemblyPreparationsTasks',
            stepTasks
        );
        this.$store.commit('showNotification', {
          content: `Imported all tasks for Step: '${step}'`,
          color: 'info',
        });
      }

      await this.$store.dispatch(
          'fetchAssemblyPreparationsModuleAssemblyTasks',
          {projectId: this.project.id}
      );
      await this.$store.dispatch('setLocationStatuses', locationStatuses);

      locationItem.loading = false;
      locationItem.completed = true;
    },
    assemblyLocationSteps(location) {
      return this.assemblyConfigurationSteps
          .filter((s) =>
              Array.from(new Set(location.activities.map((a) => a.step))).includes(
                  s.id
              )
          )
          .sort((a, b) => parseInt(a.order) - parseInt(b.order));
    },
    updateOrderToDeviationTask(activity) {
      activity.order = parseInt(activity.order);
      this.deviationOrderMenus[activity.id] = false;
    },
    addLocationDeviation(item, step) {
      item.activities.push({
        id: null,
        assembly_location: item.location.id,
        deviation: true,
        selected: true,
        activity: this.locationDeviations[item.location.id],
        order: 99,
        step: step.id,
      });
      this.locationDeviations[item.location.id] = null;
      this.locationDeviationMenus[`${item.location.id}-${step.id}`] = false;
    },
    closeDialog() {
      this.moduleType = null;
      this.documentPreview = null;
      this.locationActivities = {};
      this.locationDeviations = {};
      this.$emit('closeDialog');
    },
  },
};
</script>

<style scoped></style>

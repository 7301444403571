export const phaseOptions = [
  'dhme-init-phase',
  'dhme-structure-design-phase',
  'dhme-temporary-design-phase',
  'dhme-definitive-design-phase',
  'dhme-environmental-permit-phase',
  'dhme-construction-site-phase',
  'dhme-contact-formation-phase',
  'dhme-production-drawing-phase',
  'dhme-deliver-time-phase',
  'dhme-install-assemble-finish-phase',
];

export const wkbTaskTableName = 'CFFA_DHME_WKB_MEASUREMENT_TASKS'

export const dhmeTasksTemplate = [
  {
    friendly_id: '2',
    title: 'INITIATIEFFASE',
    options: {
      type: 'dhme-init-phase',
    },
    weight: 15,
  },
  {
    friendly_id: '24',
    title: 'SO (STRUCTUURONTWERP)',
    options: {
      type: 'dhme-structure-design-phase',
    },
    weight: 46,
  },
  {
    friendly_id: '70',
    title: 'VO (VOOR ONTWERP)',
    options: {
      type: 'dhme-temporary-design-phase',
    },
    weight: 46,
  },
  {
    friendly_id: '118',
    title: 'DO (DEFINITIEF ONTWERP)',
    options: {
      type: 'dhme-definitive-design-phase',
    },
    weight: 60,
  },
  {
    friendly_id: '150',
    title: 'VERGUNNING',
    options: {
      type: 'dhme-environmental-permit-phase',
    },
    weight: 59,
  },
  {
    friendly_id: '162',
    title: 'CONTRACTVORMING',
    options: {
      type: 'dhme-contract-phase',
    },
    weight: 20,
  },
  {
    friendly_id: '167',
    title: 'TO (TECHNISCH ONTWERP)',
    options: {
      type: 'dhme-technical-design-phase',
    },
    weight: 30,
  },
  {
    friendly_id: '179',
    title: 'UO (UITVOERINGSONTWERP)',
    options: {
      type: 'dhme-execution-design-phase',
    },
    weight: 50,
  },
  {
    friendly_id: '601',
    title: 'PRODUCTIE',
    options: {
      type: 'dhme-production-phase',
    },
    weight: 25,
  },
  {
    friendly_id: '602',
    title: 'ASSEMBLAGE',
    options: {
      type: 'dhme-assembly-phase',
    },
    weight: 25,
  },
  {
    friendly_id: '206',
    title: 'REALISATIE',
    options: {
      type: 'dhme-construction-site-phase',
    },
    weight: 98,
  },
];

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import InputEntry from '@/modules/daiwa-house-modular-europe/ProjectIntake/components/InputEntry.vue';
import { getProjectMembers } from '@/services/api/projects.api';

const namespace = 'dhmeProjectIntakeStore';

export default {
  name: 'DhmeProjectIntakeRoles',
  components: { InputEntry },
  data: () => {
    return {
      user: null,
      menus: {},
      masterMembers: [],
    };
  },
  computed: {
    ...mapState(namespace, ['isLoading']),
    ...mapGetters(namespace, ['isValid', 'members', 'users']),
    ...mapGetters({
      roles: 'dhmeProjectIntakeStore/roles',
      masterProject: 'project',
    }),
  },
  async mounted() {
    this.masterMembers = await getProjectMembers(this.masterProject.id);
    await this.loadUsersAndRoles();
  },
  methods: {
    ...mapActions(namespace, [
      'loadTablesIfNotExists',
      'selectProject',
      'loadUsersAndRoles',
      'updateRoles',
      'addRoleToUser',
      'removeRoleFromUser',
    ]),

    getMembersByRoleId(roleId) {
      return this.members.filter((m) => m.roles.some((r) => r.id === roleId));
    },

    addMemberToRole(roleId) {
      this.addRoleToUser({ roleId: roleId, userId: this.user.id });
      this.user = null;
      this.menus[roleId] = false;
    },
    removeMemberFromRole(roleId, userId) {
      this.removeRoleFromUser({ roleId, userId });
    },
    getMembersWithRole(role) {
      return this.masterMembers.filter((m) =>
        m.roles.some((r) => r.name === role.name)
      );
    },
  },
};
</script>
<script setup>
import AntInput from '@/components/AntInput.vue';
import { defaultRoles } from '@/modules/daiwa-house-modular-europe/ProjectIntake/conceptToProject';
</script>

<template>
  <div class="full-width">
    <div class="d-flex flex-column full-width">
      <div
        v-for="role in roles.filter((r) =>
          defaultRoles.map((x) => x.name).includes(r.name)
        )"
        :key="role.id"
        class="ant-border-bottom mb-4 pb-4 full-width"
      >
        <div class="d-flex">
          <h4 class="text-h6 text-truncate text-capitalize">{{ role.name }}</h4>
        </div>
        <div class="d-flex flex-wrap pa-2">
          <v-menu
            v-model="menus[role.id]"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template #activator="{ on, attrs }">
              <div
                class="d-flex flex-column align-center"
                v-bind="attrs"
                v-on="on"
              >
                <div class="add-user-icon">
                  <v-icon>mdi-plus</v-icon>
                </div>
                <span class="fs-10 font-italic mt-2">Voeg toe via email</span>
              </div>
            </template>

            <v-card>
              <v-card-text>
                <ant-input label="Naam" top-margin="mt-0">
                  <template #input-field>
                    <v-combobox
                      v-model="user"
                      :items="getMembersWithRole(role)"
                      dense
                      autofocus
                      filled
                      hide-details
                      style="width: 400px"
                      single-line
                      placeholder="Naam"
                      item-text="name"
                      @keydown.enter="addMemberToRole(role.id)"
                    />
                  </template> </ant-input
              ></v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  small
                  elevation="0"
                  color="primary"
                  @click="addMemberToRole(role.id)"
                  >Toevoegen</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>
          <div
            v-if="getMembersByRoleId(role.id).length > 0"
            class="d-flex flex-grow-1 flex-wrap"
          >
            <v-tooltip
              v-for="member in getMembersByRoleId(role.id)"
              :key="`${role.id}-${member.id}`"
              bottom
            >
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  class="d-flex flex-column align-center pos-rel member mx-4"
                  v-on="on"
                >
                  <v-icon
                    class="remove-action"
                    @click="removeMemberFromRole(role.id, member.id)"
                    >mdi-close</v-icon
                  >
                  <v-avatar color="primary" class="white--text">{{
                    member.firstname.charAt(0) + member.lastname.charAt(0)
                  }}</v-avatar>
                  <span class="fs-10 font-italic mt-2">{{ member.name }}</span>
                </div>
              </template>
              <span> {{ member.email }}</span>
            </v-tooltip>
          </div>
          <div v-else class="d-flex align-center mx-4">
            <span class="font-italic fs-12">No members added to this role</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.member {
  .remove-action {
    opacity: 0;
    position: absolute !important;
    top: -5px;
    right: -5px;
  }

  &:hover {
    .remove-action {
      opacity: 1;
    }
  }
}

.add-user-icon {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 2px dashed darkgrey;
  transition: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>

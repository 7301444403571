<script>
import { defineComponent } from 'vue';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import { mapGetters } from 'vuex';
import { DHME_MANUAL_IMPORT } from '@/modules/modules';
import FileHandlerService from '@/services/file-handler';
import { executeCustomModuleCall } from '@/services/api/module.api';
import moment from 'moment';
import { ANT_DELIMITER } from '@/services/uuid-helper';
import DhmeManualImportCreateObjectDialog from '@/modules/daiwa-house-modular-europe/ManualImport/DhmeManualImportCreateObjectDialog.vue';

export default defineComponent({
  name: 'DhmeManualImportElementContents',
  components: { DhmeManualImportCreateObjectDialog, DynamicDataTable },
  data: () => {
    return {
      isLoading: false,
      selectedCell: null,
      cellValue: null,
      csvImportFile: null,
      deleteMode: false,
      createObjectDialog: false,
      categoryFilter: null,
      currentCellValue: null,
      objectToUpdate: null,
      previousScrollLeft: 0,
      showOverlay: false,
      overlayHeight: 0,
      ctrlAltDown: false,
      isGenerating: false,
      tableOptions: {},
      lockedCells: new Set(),
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      moduleContents: 'dhmeManualImportStore/moduleContents',
      elements: 'dhmeManualImportStore/elements',
      elementContents: 'dhmeManualImportStore/elementContents',
      elementContentsTableId: 'dhmeManualImportStore/elementContentsTableId',
      objectRegister: 'dhmeManualImportStore/objectsRegister',
      lastObjectGeneration: 'dhmeManualImportStore/lastObjectGeneration',
      elementContentFilter:
        'dhmeManualImportStore/manualImportElementContentsFilter',
    }),
    elementCategories() {
      return this.elements
        .map((item) => item.element_category)
        .sort((a, b) => a.localeCompare(b));
    },
    manualImportElementContentsRecords() {
      return [...this.objectRegister]
        .filter(
          (record) =>
            record.category === this.categoryFilter && record.object_type
        )
        .sort((a, b) => a.object_type.localeCompare(b.object_type));
    },
    overlayRecords() {
      // Pagination logic
      const startIndex =
        (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
      const endIndex = startIndex + this.tableOptions.itemsPerPage;

      return this.manualImportElementContentsRecords.slice(
        startIndex,
        endIndex
      );
    },
    manualImportElementContentsHeaders() {
      let headers = [
        ...new Set(
          this.elements
            .filter((item) => item.element_category === this.categoryFilter)
            .map((item) => item.element_type)
        ),
      ]
        .filter((header) => header)
        .sort((a, b) => a.localeCompare(b))
        .map((value) => {
          return {
            value: value,
            text: value,
            hasSlot: true,
            align: 'center',
            input: true,
            sortable: false,
            width: '200px',
          };
        });

      let defaultHeaders = [
        {
          text: '',
          value: 'edit',
          type: 'text',
          hasSlot: true,
          width: '50px',
        },
        {
          text: 'Code',
          value: 'object_type',
          type: 'text',
          width: '150px',
        },
        {
          text: 'Description',
          value: 'name',
          type: 'text',
          width: '250px',
        },
        {
          text: 'Length (mm)',
          value: 'length',
          type: 'text',
          width: '150px',
        },
        {
          text: 'Width (mm)',
          value: 'width',
          type: 'text',
          width: '150px',
        },
        {
          text: 'Height (mm)',
          value: 'height',
          type: 'text',
          width: '150px',
        },
        {
          text: 'Steel Quality',
          value: 'steel_quality',
          type: 'text',
          width: '150px',
        },
      ];

      return [...defaultHeaders, ...headers];
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_MANUAL_IMPORT
      ).id;
    },
    focusedRowElementType() {
      return this.selectedCell?.split('$%ANT%$')[0] ?? null;
    },
  },
  watch: {
    categoryFilter(value) {
      this.$store.commit(
        'dhmeManualImportStore/module_manual_import_update_element_contents_filter',
        value
      );
    },
  },
  async mounted() {
    await this.$store.dispatch('dhmeManualImportStore/fetchModuleContents');
    await this.$store.dispatch('dhmeManualImportStore/fetchElementContents');
    await this.$store.dispatch('dhmeManualImportStore/fetchElements');
    await this.$store.dispatch('dhmeManualImportStore/fetchObjectRegister');
    window.addEventListener('keydown', this.setEventListeners);
    window.addEventListener('keyup', this.setEventListeners);

    this.$nextTick(() => {
      const tableWrapper = this.$el.querySelector('.v-data-table__wrapper');
      tableWrapper.addEventListener('scroll', this.handleScroll);
      this.overlayHeight = tableWrapper.offsetHeight - 36;
    });

    if (this.manualImportElementContentsFilter) {
      this.categoryFilter = this.manualImportElementContentsFilter;
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.setEventListeners);
    window.removeEventListener('keyup', this.setEventListeners);

    const tableWrapper = this.$el.querySelector('.v-data-table__wrapper');
    tableWrapper.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      const tableWrapper = event.target;
      if (tableWrapper.scrollLeft !== this.previousScrollLeft) {
        this.previousScrollLeft = tableWrapper.scrollLeft;

        this.showOverlay = tableWrapper.scrollLeft > 50;
      } else {
        // vertical scrolling
        const overlay = this.$el.querySelector('.overlay');
        if (overlay) {
          overlay.scrollTop = tableWrapper.scrollTop;
        }
      }
    },
    ANT_DELIMITER() {
      return ANT_DELIMITER;
    },
    async setEventListeners(event) {
      if (
        event.ctrlKey &&
        event.altKey &&
        event.code === 'KeyD' &&
        event.type === 'keydown'
      ) {
        this.deleteMode = !this.deleteMode;
        this.selectedCell = null;
      }

      if (this.selectedCell && event.type === 'keydown') {
        if (event.altKey && event.ctrlKey) {
          this.ctrlAltDown = true;
          let split = this.selectedCell.split(ANT_DELIMITER);
          let columnIndex = this.manualImportElementContentsHeaders.findIndex(
            (column) => column.value === split[1]
          );
          let recordIndex = this.manualImportElementContentsRecords.findIndex(
            (record) => record.object_type === split[0]
          );
          switch (event.key) {
            case 'ArrowLeft':
              if (this.cellValue !== this.currentCellValue) {
                await this.createOrUpdateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex - 1;
              this.cellValue = this.getCellValue(
                this.manualImportElementContentsRecords[recordIndex]
                  .object_type,
                this.manualImportElementContentsHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowRight':
              if (this.cellValue !== this.currentCellValue) {
                await this.createOrUpdateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex + 1;
              this.cellValue = this.getCellValue(
                this.manualImportElementContentsRecords[recordIndex]
                  .object_type,
                this.manualImportElementContentsHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowUp':
              if (this.cellValue !== this.currentCellValue) {
                await this.createOrUpdateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex - 1;
              this.cellValue = this.getCellValue(
                this.manualImportElementContentsRecords[recordIndex]
                  .object_type,
                this.manualImportElementContentsHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowDown':
              if (this.cellValue !== this.currentCellValue) {
                await this.createOrUpdateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex + 1;
              this.cellValue = this.getCellValue(
                this.manualImportElementContentsRecords[recordIndex]
                  .object_type,
                this.manualImportElementContentsHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;

            case 'Delete':
              await this.createOrUpdateRecord(
                this.manualImportElementContentsRecords[recordIndex].id,
                this.manualImportElementContentsHeaders[columnIndex].value,
                null
              );
              this.currentCellValue = null;
              return;
          }
          if (
            columnIndex >= 7 &&
            columnIndex <= this.manualImportElementContentsHeaders.length &&
            recordIndex >= 0 &&
            recordIndex < this.manualImportElementContentsRecords.length
          ) {
            this.selectedCell = `${this.manualImportElementContentsRecords[recordIndex].object_type}${ANT_DELIMITER}${this.manualImportElementContentsHeaders[columnIndex].value}`;
          }
        } else {
          this.ctrlAltDown = false;
        }
      } else {
        this.ctrlAltDown = false;
      }
    },
    async updateAndRowDown() {
      const [objectType, column] = this.selectedCell.split(
        this.ANT_DELIMITER()
      );

      if (this.lockedCells.has(`${objectType}_${column}`)) {
        return;
      }

      await this.createOrUpdateRecord(
        objectType,
        column,
        this.currentCellValue
      );

      let columnIndex = this.manualImportElementContentsHeaders.findIndex(
        (col) => col.value === column
      );
      let recordIndex = this.manualImportElementContentsRecords.findIndex(
        (record) => record.object_type === objectType
      );

      recordIndex++;

      if (
        columnIndex >= 7 &&
        columnIndex < this.manualImportElementContentsHeaders.length &&
        recordIndex >= 0 &&
        recordIndex < this.manualImportElementContentsRecords.length
      ) {
        let nextObjectType =
          this.manualImportElementContentsRecords[recordIndex].object_type;
        let nextColumn =
          this.manualImportElementContentsHeaders[columnIndex].value;

        this.selectedCell = `${nextObjectType}${this.ANT_DELIMITER()}${nextColumn}`;

        this.cellValue = this.getCellValue(nextObjectType, nextColumn);
        this.currentCellValue = this.cellValue;
      }
    },

    selectCell(header, item) {
      if (this.deleteMode) {
        this.createOrUpdateRecord(item.object_type, header.value, null);
      } else {
        this.selectedCell = `${item.object_type}${ANT_DELIMITER}${header.value}`;
        this.cellValue = this.getCellValue(item.object_type, header.value);
        this.currentCellValue = this.cellValue;
      }
    },
    getCellValue(objectType, column) {
      let record = this.elementContents.find(
        (item) =>
          item.object_type === objectType && item.element_type === column
      );

      if (record !== undefined) {
        return record.number;
      }
      return null;
    },
    resetCell(objectType, column) {
      let record = this.elementContents.find(
        (item) => item.object_type === objectType
      );
      if (record) {
        record[column] = this.cellValue;
      }
      this.selectedCell = null;
      this.cellValue = null;
      this.currentCellValue = null;
    },
    async createOrUpdateRecord(objectType, column, value) {
      const cellKey = `${objectType}_${column}`;

      if (this.lockedCells.has(cellKey)) {
        return;
      }

      this.lockedCells.add(cellKey);
      this.isLoading = true;

      try {
        let record = this.elementContents.find(
          (item) =>
            item.object_type === objectType && item.element_type === column
        );

        let body = {
          element_type: column,
          object_type: objectType,
          number: parseInt(value),
        };

        if (record) {
          await this.$store.dispatch(
            'dhmeManualImportStore/manualImportUpdateElementContentsRecord',
            {
              recordId: record.id,
              recordBody: body,
            }
          );
        } else {
          await this.$store.dispatch(
            'dhmeManualImportStore/manualImportCreateElementContentsRecord',
            {
              recordBody: body,
            }
          );
        }
      } catch (e) {
        this.$store.commit('showNotification', {
          content: 'something went wrong',
          color: 'error',
        });
        this.resetCell(objectType, column);
      } finally {
        this.lockedCells.delete(cellKey);
        this.isLoading = false;
      }
    },
    async generateObjects() {
      const createdAt = this.lastObjectGeneration?.datetime
        ? moment.unix(this.lastObjectGeneration?.datetime)
        : null;
      if (createdAt !== null && moment().diff(createdAt, 'minutes') < 15) {
        // your logic here
        this.$store.commit('showNotification', {
          content:
            'Last generation was within 15 minutes, please try again later',
          color: 'warning',
        });
      } else {
        this.isGenerating = true;
        try {
          let response = await executeCustomModuleCall(
            this.project.id,
            this.moduleId,
            'generateObjects',
            {}
          );
          this.$store.commit('showNotification', {
            content: response.message,
            color: 'info',
          });
          await this.$store.dispatch(
            'dhmeManualImportStore/setTemporaryGenerationRecord',
            'objects'
          );
        } catch (e) {
          this.$store.commit('showNotification', {
            content: e.message,
            color: 'error',
          });
        } finally {
          this.isGenerating = false;
        }
      }
    },
    exportToCsv() {
      let data = [...this.records];

      data.forEach((item) => {
        let headers = this.manualImportElementContentsHeaders
          .filter((item) => item.input)
          .map((item) => item.value);
        headers.forEach((header) => {
          item[header] = this.getCellValue(item.object_type, header);
        });
      });

      FileHandlerService.convertJSONtoCSVandDownload(
        data,
        'manual_import_objects_types_export'
      );
    },
    getDateFromTimestamp(timestamp) {
      return moment.unix(parseInt(timestamp)).format('YYYY-MM-DD HH:mm');
    },
    editObject(object) {
      this.objectToUpdate = object;
      this.createObjectDialog = true;
    },
    async importCsvData() {
      if (this.csvImportFile.name.split('.').pop() !== 'csv') {
        this.$store.commit('showNotification', {
          content: 'Only CSV files are accepted',
          color: 'error',
        });
        this.csvImportFile = undefined;
      } else {
        let file = await FileHandlerService.handleFile(this.csvImportFile);
        let data = FileHandlerService.convertBase64CSVtoJSON(file.data);
        let importData = [];
        data.forEach((item) => {
          let headers = this.manualImportElementContentsHeaders
            .filter((item) => item.input)
            .map((item) => item.value);
          let record = this.elementContentsTableId.find(
            (registerItem) => registerItem.element_type === item.object_type
          );
          let object = {
            id: record?.id ?? null,
            element_type: item.object_type,
          };
          headers.forEach((header) => {
            object[header] = item[header];
          });
          importData.push({ ...object });
        });

        let body = {
          records: FileHandlerService.convertToBase64(
            FileHandlerService.convertJSONtoCSV(importData)
          ),
        };

        this.$store
          .dispatch('importRecords', {
            tableId: this.elementContentsTableId,
            data: body,
          })
          .then(() => {
            this.csvImportFile = undefined;

            this.$store.dispatch('dhmeManualImportStore/fetchElementContents');
          });
      }
    },
    setTableOptions(options) {
      this.tableOptions = options;
    },
  },
});
</script>

<template>
  <dynamic-data-table
    table-title="Objects"
    :table-records="manualImportElementContentsRecords"
    :table-headers="manualImportElementContentsHeaders"
    class="flex-grow-1 ant-glass-background overflow-x-auto"
    :auto-sort-column="'element_type'"
    :is-loading="isLoading"
    has-options
    :use-default-export="false"
    @onOptionsUpdate="setTableOptions"
  >
    <template #body.prepend>
      <transition name="slide-right">
        <tbody
          v-if="showOverlay"
          class="overlay"
          :style="{ height: `${overlayHeight}px` }"
        >
          <tr
            v-for="record in overlayRecords"
            :key="`overlay_${record.object_type}`"
            :class="{
              'focused-row': focusedRowElementType === record.object_type,
            }"
            :style="{
              height:
                focusedRowElementType === record.object_type ? '40px' : '32px',
            }"
          >
            <td class="px-2 ant-border-left">{{ record.object_type }}</td>
          </tr>
        </tbody>
      </transition>
    </template>
    <template #table-buttons>
      <v-autocomplete
        v-model="categoryFilter"
        dense
        single-line
        hide-details
        placeholder="Category"
        filled
        :items="elementCategories"
        clearable
        prepend-inner-icon="mdi-filter"
        class="normal-text-field mr-2"
      />

      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2"
            :color="deleteMode ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
            @click="deleteMode = !deleteMode"
          >
            {{ deleteMode ? 'mdi-delete-empty' : 'mdi-delete-empty-outline' }}
          </v-icon>
        </template>
        <span>Toggle delete mode (Ctrl+Alt+D)</span>
      </v-tooltip>

      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2"
            :color="ctrlAltDown ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
          >
            mdi-arrow-all
          </v-icon>
        </template>
        <span
          >You can use Ctrl+Alt+Arrow keys to move from cell to cell when a cell
          is selected</span
        >
      </v-tooltip>
    </template>
    <template #table-options-menu>
      <v-list-item @click.stop="$refs['csv-import'].$refs.input.click()">
        <v-list-item-icon style="margin-right: 10px">
          <v-icon dense> mdi-file-import </v-icon>
        </v-list-item-icon>
        <v-list-item-title> Import CSV </v-list-item-title>
        <v-file-input
          ref="csv-import"
          v-model="csvImportFile"
          accept=".csv"
          style="display: none"
          @change="importCsvData()"
        />
      </v-list-item>
      <v-list-item @click="exportToCsv">
        <v-list-item-icon style="margin-right: 10px">
          <v-icon dense> mdi-file-download </v-icon>
        </v-list-item-icon>
        <v-list-item-title> Export to CSV </v-list-item-title>
      </v-list-item>
    </template>
    <template #table-actions>
      <v-chip v-if="deleteMode" color="warning" class="mr-2"
        ><v-icon>mdi-exclamation-thick</v-icon>DELETE MODE ACTIVE</v-chip
      >
      <div
        v-if="lastObjectGeneration"
        class="d-flex flex-column mx-2 fs-12 align-end"
      >
        <div>Last generated</div>
        <div>
          <span class="font-weight-bold">{{ lastObjectGeneration.user }}</span>
          -
          {{ getDateFromTimestamp(lastObjectGeneration.datetime) }}
        </div>
      </div>
      <v-btn
        color="primary"
        elevation="0"
        small
        :disabled="isGenerating"
        :loading="isGenerating"
        @click="generateObjects"
        >Generate</v-btn
      >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ant-icon mx-2"
            v-bind="attrs"
            v-on="on"
            @click="createObjectDialog = true"
          >
            mdi-plus
          </v-icon>
        </template>
        <span>Create object type</span>
      </v-tooltip>
      <dhme-manual-import-create-object-dialog
        :displayed="createObjectDialog"
        :category="categoryFilter"
        :object="objectToUpdate"
        @closeDialog="
          () => {
            createObjectDialog = false;
            objectToUpdate = null;
          }
        "
      />
    </template>
    <template #item.edit="{ value, rowId, item }">
      <v-icon small @click="editObject(item)">mdi-pencil</v-icon>
    </template>
    <template
      v-for="header in manualImportElementContentsHeaders.filter(
        (x) => x.input
      )"
      #[`item.${header.value}`]="{ value, rowId, item }"
    >
      <td
        :key="header.value"
        :class="{
          'ant-border-left-dark': header.isStart,
          'ant-border-left': !header.isStart,
        }"
      >
        <v-text-field
          v-if="
            selectedCell ===
            `${item.object_type}${ANT_DELIMITER()}${header.value}`
          "
          v-model="currentCellValue"
          dense
          single-line
          hide-details
          filled
          autofocus
          :disabled="lockedCells.has(`${item.object_type}_${header.value}`)"
          @change="createOrUpdateRecord(item.object_type, header.value, $event)"
          @keydown.enter="updateAndRowDown"
          @keydown.esc="resetCell(item.object_type, header.value)"
        />
        <div
          v-else
          class="full-height full-width d-flex align-center justify-center"
          @click="selectCell(header, item)"
        >
          {{ getCellValue(item.object_type, header.value) }}
        </div>
      </td>
    </template>
  </dynamic-data-table>
</template>

<style scoped lang="scss">
td {
  padding: 0 !important;
}
::v-deep .v-text-field__details {
  height: 0;
  overflow: hidden;
  min-height: 0;
}

::v-deep .x-small-text-field {
  max-width: 100px !important;
}

::v-deep .small-text-field {
  max-width: 150px !important;
}

::v-deep .normal-text-field {
  max-width: 200px !important;
}

::v-deep .v-data-table__wrapper {
  overflow-x: auto;
  position: relative;
}

.overlay {
  overflow: hidden;
  position: fixed;
  left: 0;
  background-color: white; /* Example background color */
  z-index: 2;
  border-right: solid 2px darkgrey;

  tr {
    box-sizing: border-box;
    border-bottom: solid 1px lightgray;
    display: flex;

    td {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 72px;
      font-size: 0.875rem;
    }
  }
}

.focused-row {
  background-color: lightgrey;
}
</style>

export const nonDhmeBaseTasksTemplate = {
  'dhme-init-phase': [
    {
      friendly_id: '2',
      title: 'Voorlopige gunning',
      duration: 1,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '3',
      title: 'Project startup / interne overdracht',
      duration: 1,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '4',
      title: 'Definitieve opdrachtverstrekking binnen bij Jan Snel',
      duration: 1,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '5',
      title: 'Gevraagde oplevering',
      duration: 1,
      options: {
        type: 'dhme-base-phase',
      },
    },
  ],
  'dhme-definitive-design-phase': [
    {
      friendly_id: '7',
      title: 'Uitwerken definitief ontwerp',
      duration: 4,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '8',
      title: 'Afstemming met gebruikers',
      duration: 1,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '9',
      title: 'Definitief ontwerp akkoord',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
  ],
  'dhme-environmental-permit-phase': [
    {
      friendly_id: '11',
      title: 'Opmaken locatiegebonden stukken',
      duration: 6,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '12',
      title: 'Opmaken gebouwgebonden stukken',
      duration: 2,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '13',
      title: 'Indienen aanvraag',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '14',
      title: 'Ter goedkeuring',
      duration: 8,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '15',
      title: 'Omgevingsvergunning verleend',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '16',
      title: 'Ter inzage',
      duration: 6,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '17',
      title: 'Omgevingsvergunning definitief',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '18',
      title: 'Vergunning brandveilig',
      duration: 160,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '19',
      title: 'Indienen aanvraag',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '20',
      title: 'Ter goedkeuring',
      duration: 26,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '21',
      title: 'Vergunning brandveilig gebruik verleend',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '22',
      title: 'Ter inzage',
      duration: 6,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '23',
      title: 'Vergunning brandveilig gebruik definitief',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
  ],
  'dhme-production-drawing-phase': [
    {
      friendly_id: '25',
      title: 'Aanvraag nutsvoorzieningen',
      duration: 130,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '26',
      title: 'Uitwerken technisch ontwerp (engineering)',
      duration: 4,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '27',
      title: 'TO akkoord',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '28',
      title: 'Bestellen standaard materialen',
      duration: 8,
      options: {
        type: 'dhme-base-phase',
      },
    },
  ],
  'dhme-construction-site-phase': [
    {
      friendly_id: '31',
      title: 'Grondwerk & fundering / bouwrijp maken',
      duration: 1,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '32',
      title: 'Plaatsing modules',
      duration: 1,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '33',
      title: 'Afmonteren',
      duration: 6,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '34',
      title: 'Installateurs',
      duration: 5,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '35',
      title: 'Woonrijp maken',
      duration: 2,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '36',
      title: 'Oplevering',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
    {
      friendly_id: '37',
      title: 'Overdracht aan serviceafdeling',
      duration: 0,
      options: {
        type: 'dhme-base-phase',
      },
    },
  ],
};

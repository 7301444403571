import {
  loadTables,
  memoized,
  publishRoutineFailure,
  queryClients,
  queryMetadata,
} from '@/modules/daiwa-house-modular-europe/ProjectIntake/utils';
import { Routines } from '@/modules/daiwa-house-modular-europe/ProjectIntake/Routines';
import {
  createProjectV2,
  fetchProjectV2,
} from '@/services/api/v2/projects.v2.api';
import {
  createRecordV2,
  deleteRecordV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import moment from 'moment';
import { Reducers } from '@/modules/daiwa-house-modular-europe/ProjectIntake/Reducers';
import {
  buildingEntryNames,
  clientColumns,
  clientEntryNames,
  infoEntryNames,
  metadataTableName,
  risksColumns,
} from '@/modules/daiwa-house-modular-europe/ProjectIntake/TableDefinitions';
import { createUUID } from '@/services/uuid-helper';
import {
  defaultModules,
  rolesToModulesMapping,
} from '@/modules/daiwa-house-modular-europe/ProjectIntake/conceptToProject';
import { getRolesV2 } from '@/services/api/v2/roles.v2.api';
import { value } from 'lodash/seq';
import { getProjectMembers } from '@/services/api/projects.api';
import { addTeamUser, deleteTeamUser } from '@/services/api/teams.api';

const state = {
  isValidated: false,
  loadingTables: Routines.newIdle(),
  loadingProjectsMetadata: Routines.newIdle(),
  creatingProject: Routines.newIdle(),
  selectingProject: Routines.newIdle(),
  togglingConcept: Routines.newIdle(),
  // info
  updatingInfo: Routines.newIdle(),
  // client
  loadingClients: Routines.newIdle(),
  creatingClient: Routines.newIdle(),
  removingClient: Routines.newIdle(),
  // layout
  loadingBuildings: Routines.newIdle(),
  addingBuilding: Routines.newIdle(),
  removingBuilding: Routines.newIdle(),
  selectedBuildingId: null,
  updatingBuildingInfo: Routines.newIdle(),
  addingLayer: Routines.newIdle(),
  removingLayer: Routines.newIdle(),
  // risks
  loadingRisks: Routines.newIdle(),
  storingRisk: Routines.newIdle(),
  removingRisk: Routines.newIdle(),
  // roles
  loadingUsersAndRoles: Routines.newIdle(),

  firm: null,
};

const actions = {
  loadProjectsMetaData(context) {
    return Routines.commit('loadingProjectsMetadata', context, async () => {
      const { projects } = await queryTablesV2({
        tables: [
          {
            name: metadataTableName,
            project: context.rootGetters.project.id,
            as: 'projects',
            columns: [
              {
                name: 'project_id',
              },
              {
                name: 'project_number',
              },
              {
                name: 'name',
              },
              {
                name: 'is_concept',
              },
              {
                name: 'created',
              },
            ],
          },
        ],
      });
      ``;
      return projects;
    }).then(publishRoutineFailure(this));
  },
  loadTablesIfNotExists(context) {
    return Routines.commit(
      'loadingTables',
      context,
      () => loadTables(context.rootGetters.project.id),
      (routine) => Routines.isIdle(routine) || Routines.isFailure(routine) // So only run if it is not already running or already successful
    ).then(publishRoutineFailure(this));
  },
  async createProject(context, name) {
    const routine = await Routines.commit(
      'creatingProject',
      context,
      async () => {
        const date = moment().format('YYYY-MM-DD');
        const projectResponse = await createProjectV2({
          license: context.rootGetters.project.license,
          name: name,
          description: `Project ${name} created on ${date} `,
          number: `C#${generateUniqueCode()}`,
          modules: defaultModules,
          roles: rolesToModulesMapping,
        });

        // create record with concept form
        return await createRecordV2(context.getters.tables.metadata, {
          name,
          project_id: projectResponse.id,
          project_number: projectResponse.number,
          is_concept: true,
          created: date,
        });
      }
    ).then(publishRoutineFailure(this));

    if (Routines.isSuccess(routine)) {
      context.state.loadingProjectsMetadata.result?.records?.push(
        routine.result
      );
    }
  },
  selectProject(context, projectId) {
    const id = projectId;

    const masterProjectId = context.rootGetters.project.id;

    return Routines.commit('selectingProject', context, async () => {
      const records = await Promise.allSettled([
        queryMetadata(
          masterProjectId,
          id,
          context.getters.tables.metadata.columns
        ),
        fetchProjectV2(id),
      ]);

      return {
        metadata: records[0].value.records[0],
        project: records[1].value,
      };
    }).then(publishRoutineFailure(this));
  },
  async toggleConcept(context) {
    const metadata = context.getters.selectedProjectMetadata;
    const tableId = context.getters.tables.metadata.id;

    const value = !metadata.is_concept;
    const routine = await Routines.commit('togglingConcept', context, () =>
      updateRecordV2(tableId, metadata.id, { is_concept: value })
    ).then(publishRoutineFailure(this));

    if (Routines.isSuccess(routine)) {
      context.commit('setIsConcept', value);
    }
  },
  loadClients(context, concept) {
    concept = concept ?? context.getters.selectedProjectMetadata.id;
    const project = context.rootGetters.project.id;

    return Routines.commit('loadingClients', context, () =>
      queryClients(project, concept)
    ).then(publishRoutineFailure(this));
  },
  async addClient(context, client) {
    const routine = await Routines.commit(
      'creatingClient',
      context,
      async () => {
        const projectId = context.getters.selectedProject.id;
        const table = context.getters.tables.clients.id;
        const record = { project_id: projectId, ...client };
        return await createRecordV2(table, record);
      },
      (routine) =>
        !Routines.isRunning(routine) ||
        Routines.isSuccess(context.state.loadingClients)
    ).then(publishRoutineFailure(this));

    if (Routines.isSuccess(routine)) {
      context.state.loadingClients.result?.records.push(routine.result);
    }
  },
  async removeClient(context, client) {
    const routine = await Routines.commit(
      'removingClient',
      context,
      () => deleteRecordV2(context.getters.tables.clients, client.id),
      (routine) =>
        !Routines.isRunning(routine) ||
        Routines.isSuccess(context.state.loadingClients)
    ).then(publishRoutineFailure(this));

    if (Routines.isSuccess(routine)) {
      context.state.loadingClients.result?.records.reduce(
        ...Reducers.filterInPlace(({ id }) => routine.result.ids.includes(id))
      );
    }
  },
  loadRisks(context) {
    return Routines.commit('loadingRisks', context, async () => {
      const columns = risksColumns.map((name) =>
        name === 'project_id'
          ? {
              name,
              conditions: [
                { operator: '=', value: context.getters.selectedProject.id },
              ],
            }
          : { name }
      );
      const name = context.getters.tables.risks.name;
      const project = context.rootGetters.project.id;
      const { risks } = await queryTablesV2({
        tables: [{ name, project, as: 'risks', columns }],
      });

      risks.records.sort((a, b) => a.ordinal - b.ordinal);

      return risks;
    }).then(publishRoutineFailure(this));
  },
  async storeRisk(context, { title, description, chance, consequence, id }) {
    let tableId = context.getters.tables.risks.id;
    if (id) {
      const risk = { title, description, chance, consequence };
      const routine = await Routines.commit('storingRisk', context, () =>
        updateRecordV2(tableId, id, risk)
      ).then(publishRoutineFailure(this));

      if (Routines.isSuccess(routine)) {
        context.commit('updateRisk', risk);
      }
    } else {
      const record = {
        project_id: context.getters.selectedProject.id,
        title,
        description,
        chance,
        consequence,
        ordinal:
          context.getters.risks
            .map((r) => r.ordinal)
            .reduce(...Reducers.max()) + 1,
      };
      const routine = await Routines.commit('storingRisk', context, () =>
        createRecordV2(tableId, record)
      ).then(publishRoutineFailure(this));

      if (Routines.isSuccess(routine)) {
        context.commit('addRisk', routine.result);
      }
    }
  },
  async removeRisk(context, risk) {
    const routine = await Routines.commit('removingRisk', context, () =>
      deleteRecordV2(context.getters.tables.risks.id, risk.id ?? risk)
    ).then(publishRoutineFailure(this));

    if (Routines.isSuccess(routine)) {
      context.commit('removeRisk', routine.result.ids);
    }
  },
  async loadUsersAndRoles(context) {
    const projectId = context.getters.selectedProject.id;

    return Routines.commit('loadingUsersAndRoles', context, async () => {
      return {
        roles: await getRolesV2(projectId),
        members: await getProjectMembers(projectId),
      };
    }).then(publishRoutineFailure(this));
  },

  async addRoleToUser(context, { roleId, userId }) {
    const projectId = context.getters.selectedProject.id;

    await addTeamUser(roleId, projectId, userId);

    await context.dispatch('loadUsersAndRoles');
  },

  async removeRoleFromUser(context, { roleId, userId }) {
    const projectId = context.getters.selectedProject.id;

    await deleteTeamUser(roleId, userId, projectId);

    await context.dispatch('loadUsersAndRoles');
  },
};

const mutations = {
  reset(state) {
    state.selectingProject.result = {};
  },
  ...Routines.newMutation('loadingTables'),
  ...Routines.newMutation('loadingProjectsMetadata'),
  ...Routines.newMutation('creatingProject'),
  ...Routines.newMutation('selectingProject'),
  ...Routines.newMutation('togglingConcept'),
  ...Routines.newMutation('updatingInfo'),
  setIsConcept: (state, value) => {
    const concept = state.selectingProject.result?.metadata;

    if (concept) {
      concept.is_concept = value;
    }
  },
  updateMetadata: (state, { key, value }) => {
    if (state.selectingProject.result?.metadata) {
      state.selectingProject.result.metadata[key] = value;
    }
  },
  ...Routines.newMutation('loadingClients'),
  ...Routines.newMutation('creatingClient'),
  ...Routines.newMutation('removingClient'),
  ...Routines.newMutation('loadingBuildings'),
  ...Routines.newMutation('removingBuilding'),
  ...Routines.newMutation('addingBuilding'),
  ...Routines.newMutation('updatingBuildingInfo'),
  setBuildingInfo(state, { buildingId, name, value }) {
    state.loadingBuildings.result.buildings.records.forEach(
      (b) => b.id === buildingId && (b[name] = value)
    );
  },
  ...Routines.newMutation('addingLayer'),
  ...Routines.newMutation('removingLayer'),
  appendLayer(state, { buildingId, layer }) {
    const buildings = state.loadingBuildings.result.buildings;

    buildings.records = buildings.records.map((building) => {
      if (building.id === buildingId) {
        !building.layers && (building.layers = []);
        building.layers.push(layer);
      }

      return building;
    });
  },
  spliceLayer(state, { buildingId, layers }) {
    const buildings = state.loadingBuildings.result.buildings;

    buildings.records = buildings.records.map((building) => {
      if (building.id === buildingId) {
        building.layers = layers;
      }

      return building;
    });
  },
  ...Routines.newMutation('loadingRisks'),
  ...Routines.newMutation('storingRisk'),
  ...Routines.newMutation('removingRisk'),
  updateRisk(state, risk) {
    state.loadingRisks?.result?.records.forEach((r) => {
      if (r.id === risk.id) {
        Object.assign(r, risk);
      }
    });
  },
  addRisk(state, risk) {
    state.loadingRisks?.result?.records.push(risk);
  },
  removeRisk(state, riskIds) {
    state.loadingRisks?.result?.records?.reduce(
      ...Reducers.filterInPlace((r) => riskIds.includes(r.id))
    );
  },
  ...Routines.newMutation('loadingUsersAndRoles'),
};

const getters = {
  isLoading: (state) => Object.values(state).some(Routines.isRunning),
  tables: (state) => state.loadingTables?.result,
  infoEntries: memoized((state) => (old) => {
    const metadata = state.selectingProject?.result?.metadata ?? {};
    const columns = state.loadingTables.result?.metadata.columns ?? [];

    const valueOf = (column) =>
      Object.entries(metadata).find(([name]) => name === column.name)?.[1] ??
      column.default_value;
    const result = columns
      ?.filter((c) => infoEntryNames.includes(c.name))
      .sort((a, b) => a.order - b.order)
      .map((column) => Object.assign(column, { value: valueOf(column) }));

    return result ?? old;
  }),
  clientEntries: memoized((state) => (old) => {
    const metadata = state.selectingProject?.result?.metadata ?? {};
    const columns = state.loadingTables.result?.metadata.columns ?? [];
    const valueOf = (column) =>
      Object.entries(metadata).find(([name]) => name === column.name)?.[1] ??
      column.default_value;
    const result = columns
      ?.filter((c) => clientEntryNames.includes(c.name))
      .sort((a, b) => a.order - b.order)
      .map((column) =>
        Object.assign(column, {
          value: valueOf(column),
          prefixIcon: clientColumns.find((x) => x.name === column.name)
            ?.prefixIcon,
        })
      );

    return result ?? old;
  }),
  buildingEntries: memoized((state, getters) => (old) => {
    const building =
      state?.loadingBuildings?.result?.buildings?.records?.find(
        (b) => b.id === state.selectedBuildingId
      ) ?? {};
    const columns = getters.tables?.buildings.columns ?? [];
    const valueOf = (column) =>
      Object.entries(building).find(([name]) => name === column.name)?.[1] ??
      column.default_value;
    const result = columns
      ?.filter((c) => buildingEntryNames.includes(c.name))
      .sort((a, b) => a.order - b.order)
      .map((column) =>
        Object.assign(column, { key: createUUID(), value: valueOf(column) })
      );

    return result ?? old;
  }),
  selectedProjectMetadata: (s) => s.selectingProject?.result?.metadata,
  selectedProject: (s) => s.selectingProject?.result?.project,
  projects: memoized(
    (s) => (old) => s.loadingProjectsMetadata?.result?.records ?? old ?? []
  ),
  clients: memoized(
    (s) => (old) => s.loadingClients?.result?.records ?? old ?? []
  ),
  buildings: memoized(
    (state) => (old) =>
      state?.loadingBuildings?.result?.buildings?.records ?? old ?? []
  ),
  risks: memoized(
    (s) => (old) =>
      s.loadingRisks?.result?.records?.map((risk) => ({
        ...risk,
        risk: risk.chance * risk.consequence,
      })) ??
      old ??
      []
  ),
  roles: memoized(
    (s) => (old) => state.loadingUsersAndRoles.result?.roles ?? old ?? []
  ),
  members: memoized(
    (s) => (old) => state.loadingUsersAndRoles.result?.members ?? old ?? []
  ),
  users: memoized(
    (s) => (old) => state.loadingUsersAndRoles.result?.users ?? old ?? []
  ),
  firm: (s) => s.selectingProject?.result?.metadata?.firm,
};

function generateUniqueCode() {
  return Math.random().toString(36).substring(2, 7);
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
